import { FC } from "react"

import { BillingTypeCodes } from "data"
import { useLaboratoryOrder } from "laboratory/hooks"
import { SkeletonLoader } from "commons"
import { LaboratoryOrderResults } from "laboratory/components/LaboratoryOrderResults"

const CPLabResultsDetails: FC<Props> = ({ patientId, labOrderId, currentOrganizationId }) => {
  const { laboratoryOrder, isLoading: isLoadingLabResults } = useLaboratoryOrder(
    currentOrganizationId,
    labOrderId as string,
    patientId,
  )

  return (
    <div className="py-3 flex flex-col space-y-3">
      <h3 className="text-gray-500 font-medium text-sm">Results</h3>
      {isLoadingLabResults ? (
        <SkeletonLoader loaderType="min-card" repeats={1} />
      ) : (
        <LaboratoryOrderResults
          panels={laboratoryOrder.panels}
          combo={laboratoryOrder.combo}
          previousResults={laboratoryOrder.previousResults}
          isOrgExemptLabPayment={false}
          hidePrice={laboratoryOrder.billingType === BillingTypeCodes.INSURANCE}
        />
      )}
    </div>
  )
}

type Props = { patientId: string; labOrderId?: string; currentOrganizationId: string }

export { CPLabResultsDetails }
