import { Patient, Address, ResourceObject } from "fhir"
import { getAddressIndexByType } from "fhir/utils"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useClient } from "api"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"
import { commonsQueryKeys, CustomError } from "commons"

const useUpdatePatientAddress = (onSuccess?: () => void) => {
  const queryClient = useQueryClient()
  const { patch } = useClient()

  const updateAdress = async ({ address, patient }: { address: Address; patient: Patient }) => {
    const index = getAddressIndexByType(patient.address, address.type)
    const newAddress = patient.address
      ? index > -1
        ? [...patient.address.slice(0, index), address, ...patient.address.slice(index + 1)]
        : [...patient.address, address]
      : [address]

    return patch("Patient", patient.id as string, { address: newAddress, meta: patient.meta } as ResourceObject)
  }

  const { mutate: updateAddress, isPending: isUpdating } = useMutation({
    mutationFn: updateAdress,
    onError: (error: CustomError, context) => {
      if (!!error.cause?.name && ["409", "412"].includes(error.cause.name)) {
        // Conflict error or precondition failed error are thrown when the resource has been modified by someone
        queryClient.invalidateQueries({ queryKey: commonsQueryKeys.loggedIn(context.patient.id) })
      }
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async (_, { patient }) => {
      await queryClient.invalidateQueries({ queryKey: commonsQueryKeys.loggedIn(patient.id) })
      await queryClient.invalidateQueries({ queryKey: ["logged-user-patients"] })
      displayNotificationSuccess("Address updated successfully!")
      onSuccess?.()
    },
  })

  return { updateAddress, isUpdating }
}

export { useUpdatePatientAddress }
