import { useSuspenseQuery } from "@tanstack/react-query"
import { Patient } from "fhir"

import { useClient } from "api"
import { commonsQueryKeys, CustomError } from "commons"

import { useAuth } from "../hooks/useAuth"
import { useLoggedUserPatients } from "./useLoggedUserPatients"

const useLoggedInPatient = (patientId: string | undefined) => {
  const { read } = useClient()
  const { setLinkedResource } = useAuth()
  const { isLinkedPatient } = useLoggedUserPatients()
  const queryKey = commonsQueryKeys.loggedIn(patientId)

  const { data, isLoading, error } = useSuspenseQuery({
    queryKey,
    queryFn: async () => {
      const patient = await read<Patient>({ endpoint: "Patient", id: patientId })

      if (!patient?.id) {
        throw new Error("Not found", {
          cause: { name: "404", message: "Not patient found", logoutFlag: true },
        } as CustomError)
      }

      if (!patient?.managingOrganization?.id) {
        throw new Error("Not found", {
          cause: { name: "404", message: "No organization linked to patient", logoutFlag: true },
        } as CustomError)
      }

      if (!isLinkedPatient(patient.id)) {
        throw new Error("Forbidden", {
          cause: {
            name: "403",
            message: "Forbidden Access. Patient is not a linked patient for this user",
            logoutFlag: true,
          },
        } as CustomError)
      }

      setLinkedResource(patient)

      return patient
    },
    meta: { context: { queryKey, patientId } },
  })

  return { loggedInPatient: data as Patient, isLoading, error }
}

export { useLoggedInPatient }
