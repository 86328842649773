import { AccountBETACreditCardArray, Address, Coding, PatientContactArray, RelatedPerson } from "fhir"
import { useFormikContext } from "formik"
import { useEffect, useMemo, useRef } from "react"

import { isPoBoxAddress } from "utils"
import { useValueSet } from "value-set"

import { DropdownField } from "./DropdownField"
import { InputField } from "./InputField"

const AddressField = ({
  parentFieldName,
  showAutoComplete = true,
  disabled = false,
  limitToContinentalUS = false,
  checkPOBoxAddress = true,
  context,
}: Props) => {
  const parentFieldFullName = parentFieldName ? parentFieldName + "." : ""
  const { codes: states } = useValueSet("continental-usa-states")
  const { address, setFieldValue } = useAddressContext(context)

  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if ("google" in window) {
      const options: {
        componentRestrictions: { country: string }
        fields: string[]
        types: string[]
        bounds?: google.maps.LatLngBounds
        strictBounds?: boolean
      } = {
        componentRestrictions: { country: "us" },
        fields: ["address_components"],
        types: ["address"],
      }

      const southwest = { lat: 26.285164489602305, lng: -124.90260564687212 }
      const northeast = { lat: 44.78482115483085, lng: -66.72621694317236 }
      const usBounds = new google.maps.LatLngBounds(southwest, northeast)

      if (limitToContinentalUS) {
        options.bounds = usBounds
        options.strictBounds = true
      }

      try {
        const autoComplete: google.maps.places.Autocomplete = new google.maps.places.Autocomplete(
          inputRef.current as HTMLInputElement,
          options,
        )

        autoComplete.addListener("place_changed", () => {
          const place = autoComplete?.getPlace()
          let route = ""
          let streetNumber = ""

          if (place) {
            for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
              const componentType = component.types[0]

              switch (componentType) {
                case "route": {
                  route = component.long_name
                  break
                }

                case "street_number": {
                  streetNumber = component.long_name
                  break
                }

                case "postal_code": {
                  setFieldValue(`${parentFieldFullName}postalCode`, component.long_name)
                  break
                }

                case "locality":
                  setFieldValue(`${parentFieldFullName}city`, component.long_name)
                  break

                case "administrative_area_level_1": {
                  setFieldValue(`${parentFieldFullName}state`, component.short_name)
                  break
                }
              }
            }

            setFieldValue(`${parentFieldFullName}line[0]`, `${streetNumber} ${route}`)
            setFieldValue(`${parentFieldFullName}line[1]`, "")
          }
        })
        // eslint-disable-next-line no-empty
      } catch {}
    }
  }, [parentFieldFullName, setFieldValue, limitToContinentalUS])

  return (
    <>
      <InputField
        field={`${parentFieldFullName}line[0]`}
        label="Address Line 1"
        disabled={disabled}
        autocomplete="off"
        type="text"
        ref={showAutoComplete && "google" in window ? inputRef : null}
      />
      {checkPOBoxAddress && isPoBoxAddress(address) && (
        <span className="text-orange-400 text-sm">
          It appears that you are attempting to set a P.O. Box address. Please be aware that P.O. Boxes might not be
          suitable for receiving deliveries
        </span>
      )}
      <InputField
        field={`${parentFieldFullName}line[1]`}
        label="Address Line 2"
        disabled={disabled}
        type="text"
        autocomplete="off"
      />

      <div className="@container col-span-2 w-full">
        <div className="p-fluid grid grid-cols-1 @sm:grid-cols-3 gap-4">
          <InputField
            field={`${parentFieldFullName}city`}
            label="City"
            className="grow"
            disabled={disabled}
            autocomplete="off"
            type="text"
          />
          <DropdownField
            field={`${parentFieldFullName}state`}
            label="State"
            className="grow"
            options={states as Coding[]}
            optionLabel="display"
            disabled={disabled}
          />
          <InputField
            field={`${parentFieldFullName}postalCode`}
            label="ZIP Code"
            className="grow"
            disabled={disabled}
            autocomplete="off"
            type="text"
          />
        </div>
      </div>
    </>
  )
}

const useAddressContext = (context: AddressContextType) => {
  const { setFieldValue, values } = useFormikContext()

  const address = useMemo(() => {
    switch (true) {
      case context === "CreditCard":
        return (values as AccountBETACreditCardArray).billingAddress
      case context === "Address":
        return values as Address
      case context === "PatientContact":
        return (values as PatientContactArray).address
      case context === "RelatedPerson":
        return (values as RelatedPerson).address?.[0]
      default:
        return undefined
    }
  }, [context, values])

  return { address, setFieldValue }
}

type AddressContextType = "CreditCard" | "Address" | "PatientContact" | "RelatedPerson"

type Props = {
  context: AddressContextType
  parentFieldName?: string
  showAutoComplete?: boolean
  disabled?: boolean
  limitToContinentalUS?: boolean
  checkPOBoxAddress?: boolean
}

export { AddressField }
