import { Coding, Identifier, ParametersParameterArrayArray, ParametersParameterArrayValue } from "fhir"

const SKU_SYSTEM = "http://chartedhealth.com/fhir/identifiers/sku"

const getParamsSkuCodesByQty = (codes: (Coding | ParametersParameterArrayValue)[]) => {
  const skuCodes = codes?.filter((code) =>
    code?.code
      ? (code as Coding)?.system === SKU_SYSTEM
      : (code as ParametersParameterArrayValue)?.Coding?.system === SKU_SYSTEM,
  )

  const uniqueIdentifiers = new Set<string>(
    skuCodes?.map((code) =>
      code?.code
        ? ((code as Coding).code as string)
        : `${(code as ParametersParameterArrayValue)?.Coding?.code}-${(code as ParametersParameterArrayValue)?.Quantity?.value}`,
    ),
  )

  const { groupedItems, itemsWithoutQty } = Array.from(uniqueIdentifiers).reduce(
    (acc, item) => {
      const effectiveItem = skuCodes.find((code) =>
        code?.code
          ? (code as Coding)?.code === item
          : `${(code as ParametersParameterArrayValue)?.Coding?.code}-${(code as ParametersParameterArrayValue)?.Quantity?.value}` ===
            item,
      )

      if ((effectiveItem as ParametersParameterArrayValue)?.Coding) {
        const currentItem = effectiveItem as ParametersParameterArrayValue
        const key = `${currentItem.Quantity?.value}`

        if (!acc.groupedItems[key]) {
          acc.groupedItems[key] = {
            name: "product-info",
            part: [
              {
                name: "code",
                value: {
                  Coding: currentItem.Coding,
                },
              },
              {
                name: "quantity",
                value: {
                  Quantity: {
                    value: currentItem.Quantity?.value,
                  },
                },
              },
            ],
          }
        } else {
          const currentPart = [...(acc.groupedItems[key]?.part ?? [])]
          acc.groupedItems[key].part = [
            {
              name: "code",
              value: {
                Coding: currentItem.Coding,
              },
            },
            ...currentPart,
          ]
        }
      } else {
        acc.itemsWithoutQty.push({
          name: "code",
          value: {
            Coding: effectiveItem as Coding,
          },
        })
      }
      return acc
    },
    {
      groupedItems: {} as { [key: string]: ParametersParameterArrayArray },
      itemsWithoutQty: [] as ParametersParameterArrayArray[],
    },
  )

  return [
    ...Object.values(groupedItems),
    ...(itemsWithoutQty.length ? [{ name: "product-info", part: itemsWithoutQty }] : []),
  ] as ParametersParameterArrayArray[]
}

const getPDSkuValue = (identifiers: Identifier[] | undefined) =>
  identifiers?.find((id) => id.system === SKU_SYSTEM)?.value

export { getParamsSkuCodesByQty, SKU_SYSTEM, getPDSkuValue }
