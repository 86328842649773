import { useContext } from "react"

import { CPOERequestsContext } from "../context/CPOERequestsProvider"

const useCPOERequestsContext = () => {
  const context = useContext(CPOERequestsContext)

  if (context === undefined) {
    throw new Error("Couldn't create CPOERequests context")
  }

  return context
}

export { useCPOERequestsContext }
