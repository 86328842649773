import { useContext } from "react"

import { SidebarCtx } from "../components/SidebarContextProvider"

const useSidebarContext = () => {
  const context = useContext(SidebarCtx)

  if (context === undefined) {
    throw new Error("Couldn't create Sidebar Context")
  }

  return context
}

export { useSidebarContext }
