import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEllipsisVertical, faExternalLink, faSpinner } from "@fortawesome/pro-regular-svg-icons"
import { Menu } from "primereact/menu"
import { MenuItem as MenuItemProps } from "primereact/menuitem"
import { classNames } from "primereact/utils"
import { ReactHTML, ReactNode, useRef } from "react"

import { Badge, BadgeProps } from "./Badge"
import { MenuStyles } from "../types"

const StackedListItem = ({
  modelData: {
    itemClassName,
    leftData,
    rightData,
    image,
    badge,
    menu,
    menuStyle = MenuStyles.Dropdown,
    isLoading,
    columnStyle = "Edge",
    onClick,
    onClickImage,
  },
  rowHover,
  itemPadding,
  className = "py-4",
  allowExpandItems = false,
  contentClassName,
  customTag = "li",
  keepMenuSpace = true,
}: StackedListProps) => {
  const menuRef = useRef<Menu>(null)

  const Tag = customTag ?? "li"

  return (
    <Tag
      className={classNames("flex @md:items-center", className, itemClassName, {
        "hover:bg-gray-50": rowHover,
        "cursor-pointer": onClick,
        "px-4 sm:px-6": itemPadding,
      })}
    >
      {image && (
        <div className={classNames("flex flex-shrink-0", { "cursor-pointer": onClickImage })} onClick={onClickImage}>
          {image}
        </div>
      )}
      <div
        className={classNames(
          "min-w-0 flex-1",
          {
            "@md:flex @md:justify-between": columnStyle === "Edge",
            "@md:grid @md:grid-cols-2": columnStyle === "Grid",
            "px-4": image,
            "@md:items-center": !rightData || (rightData.length === 1 && leftData && leftData.length <= 2),
          },
          contentClassName,
        )}
        onClick={onClick}
      >
        <div className="truncate">
          {leftData?.map(
            ({ lineItems: lineItem, withVerticalDivider }, line) =>
              lineItem.length > 0 && (
                <div
                  key={`ml${line}`}
                  className={classNames("text-sm", {
                    "@md:flex": !allowExpandItems,
                    "text-gray-900 font-medium": line === 0,
                    "text-gray-400 text-xs mt-1": line !== 0,
                    "divide-x-2 gap-1": withVerticalDivider,
                  })}
                >
                  {lineItem.map((item, index) =>
                    item.component ? (
                      <span key={`ml${line}i${index}`} className="min-w-0" title={item.name}>
                        {item.component}
                      </span>
                    ) : (
                      <span
                        key={`ml${line}i${index}`}
                        className={classNames("@md:flex items-center truncate", {
                          "mt-1 @md:mt-0": index !== 0,
                          "cursor-pointer": item.onClick,
                          "@md:pl-1 @md:first:pl-0": withVerticalDivider,
                          "@md:ml-3": !withVerticalDivider,
                        })}
                        title={item.name ?? item.value}
                        onClick={item.onClick}
                      >
                        {item.icon && (
                          <FontAwesomeIcon
                            icon={item.icon}
                            className="fa-fw mr-1.5 h-3.5 w-3.5 flex-shrink-0 text-gray-400"
                          />
                        )}
                        {item.value}
                      </span>
                    ),
                  )}
                </div>
              ),
          )}
        </div>
        {rightData && (
          <div
            className={classNames("w-fit @md:mt-0 @md:text-right @md:flex @md:flex-col", {
              "@md:items-end": columnStyle === "Edge",
              "@md:items-start": columnStyle === "Grid",
            })}
          >
            {badge && (
              <Badge
                className="hidden @md:inline-flex"
                text={badge.text}
                colorStyle={badge.colorStyle}
                size={badge.size}
              />
            )}
            {rightData.map(({ lineItems: lineItem }, line) => (
              <div
                key={`sl${line}`}
                className={classNames("@md:flex text-gray-400 text-xs", {
                  "mt-1": !!badge || line !== 0,
                })}
              >
                {lineItem.map((item, index) =>
                  item.component ? (
                    <span key={`ml${line}i${index}`} className="min-w-0">
                      {item.component}
                    </span>
                  ) : (
                    <p
                      key={`sl${line}i${index}`}
                      className={classNames("@md:flex items-center truncate", {
                        "mt-1 @md:mt-0 @md:ml-3": index !== 0,
                        "cursor-pointer": item.onClick,
                      })}
                      title={item.name ?? item.value}
                      onClick={item.onClick}
                    >
                      {item.icon && (
                        <FontAwesomeIcon
                          icon={item.icon}
                          className="fa-fw mr-1.5 h-3.5 w-3.5 flex-shrink-0 text-gray-400"
                        />
                      )}
                      {item.value}
                    </p>
                  ),
                )}
              </div>
            ))}
          </div>
        )}
      </div>
      {badge && (
        <Badge
          className={classNames(
            "h-fit",
            {
              "@md:hidden": rightData,
              "mr-11": !!keepMenuSpace && !menu?.length,
            },
            badge.className,
          )}
          text={badge.text}
          colorStyle={badge.colorStyle}
          size={badge.size}
        />
      )}
      {(isLoading || (menu && menu.length > 0)) && (
        <div className="ml-3 mr-1 flex-shrink-0 text-gray-400 text-sm">
          {isLoading ? (
            <span className="flex justify-center items-center h-7 w-7">
              <FontAwesomeIcon icon={faSpinner} spin className="fa-fw" />
            </span>
          ) : menuStyle === MenuStyles.ExternalAction ? (
            <div className="flex gap-2 items-center">
              {menu?.map((menuItem, index) => (
                <span
                  key={index}
                  className="cursor-pointer w-5 h-5 flex items-center justify-end"
                  title={menuItem.label}
                  onClick={(event) => {
                    !menuItem.disabled && menuItem.command?.({ originalEvent: event, item: menuItem })
                  }}
                >
                  {menuItem.loading ? (
                    <FontAwesomeIcon icon={faSpinner} spin className="fa-fw" />
                  ) : (
                    menuItem.icon ?? <FontAwesomeIcon icon={faExternalLink} className="fa-fw" />
                  )}
                </span>
              ))}
            </div>
          ) : menuStyle === MenuStyles.ActionItems ? (
            menu?.map((menuItem, index) => (
              <span
                className="cursor-pointer mx-1 rounded-full inline-flex items-center justify-center hover:bg-gray-100 hover:border hover:border-gray-200 w-6 h-6"
                title={menuItem.label}
                onClick={(event) => {
                  !menuItem.disabled && menuItem.command?.({ originalEvent: event, item: menuItem })
                }}
                key={menuItem.label ?? index}
              >
                {menuItem.icon ?? menuItem.label}
              </span>
            ))
          ) : (
            <span
              className="cursor-pointer flex hover:bg-gray-100 p-1 rounded-full"
              title="Menu"
              onClick={(event) => {
                menuRef.current && menuRef.current.toggle(event)
              }}
            >
              <FontAwesomeIcon icon={faEllipsisVertical} className="fa-fw h-5 w-5" />
              <Menu model={menu} popup ref={menuRef} id="popup_menu" style={{ fontSize: "small" }} />
            </span>
          )}
        </div>
      )}
    </Tag>
  )
}

export type CommonProps = {
  /** Apply row hovered style */
  rowHover?: boolean
  /** Apply horizontal padding */
  itemPadding?: boolean
  allowExpandItems?: boolean
}

export type StackedListProps = CommonProps & {
  className?: string
  modelData: StackedListItemProps
  contentClassName?: string
  customTag?: keyof ReactHTML
  keepMenuSpace?: boolean
}

export type StackedListItemProps = {
  itemClassName?: string
  image?: ReactNode
  onClickImage?(): void
  /** Left Column Data */
  leftData?: StackedLineItemProps[]
  /** Right Column Data */
  rightData?: StackedLineItemProps[]
  badge?: BadgeProps
  /** Dropdown menu */
  menu?: MenuItem[]
  menuStyle?: MenuStyles
  isLoading?: boolean
  columnStyle?: "Edge" | "Grid"
  onClick?(): void
}

type MenuItem = MenuItemProps & { loading?: boolean }

export type StackedLineItemProps = {
  lineItems: StackedItemProps[]
  withVerticalDivider?: boolean
}

export type StackedItemProps = {
  name?: string
  value?: string
  component?: JSX.Element
  icon?: IconDefinition
  onClick?(): void
}

export { StackedListItem }
