import { useQuery } from "@tanstack/react-query"
import { Account, AccountBETACreditCardArray, getResources } from "fhir"

import { useClient } from "api"

import { accountKeys } from "../query-keys"

const usePatientAccount = (patientId: string, patientName?: string, patientEmail?: string) => {
  const { search } = useClient()
  const queryKey = accountKeys.withPatientId(patientId)

  const { data, isLoading, isFetching, isError } = useQuery({
    queryKey,
    queryFn: async () => {
      const filters = new URLSearchParams({
        status: "active",
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/Account`, filters })

      const patientAccount = getResources(bundle) as Account[]

      if (!patientAccount?.[0]?.id)
        throw new Error("Not found", {
          cause: {
            name: "404",
            message: `Not found an account linked to patient ${patientName ?? ""} ${
              patientEmail ? `with email ${patientEmail}` : ""
            }`,
          },
        })
      if (patientAccount?.length > 1)
        throw new Error("Multiple accounts found", {
          cause: {
            name: "422",
            message: `Should be only one account linked to patient ${patientName ?? ""} ${
              patientEmail ? `with email ${patientEmail}` : ""
            }`,
          },
        })

      let defaultCreditCard

      if (patientAccount?.[0]?.defaultCreditCard) {
        const [defaultCcType, defaultCcLast4Digits] = patientAccount[0].defaultCreditCard.split("|")
        defaultCreditCard = patientAccount[0].creditCard?.find(
          (cc) => cc.type === defaultCcType && cc.last4Digits === defaultCcLast4Digits,
        ) as AccountBETACreditCardArray
      }

      return { account: patientAccount[0], defaultCreditCard }
    },
    refetchOnWindowFocus: false,
    throwOnError: true,
    meta: { context: { queryKey, patientId } },
  })

  return {
    patientAccount: data?.account,
    defaultCreditCard: data?.defaultCreditCard,
    isError,
    isLoadingAccount: isLoading || isFetching,
  }
}

export { usePatientAccount }
