import { useEffect, useState } from "react"

export type TNotificationBuild = {
  title: string
  options?: NotificationOptions | undefined
}

// onPermissionDenied => some action when the notification permissions is disabled in browser

export const useBrowserNotification = ({ onPermissionDenied }: { onPermissionDenied?: () => void } = {}) => {
  const [notification, setNotification] = useState<Notification | null>(null)

  const notificationPermission = () => Notification.requestPermission()

  const handleNotification = async ({ title, options }: TNotificationBuild) => {
    if (document.visibilityState === "hidden") {
      const newNotification = new Notification(title, options)
      setNotification(newNotification)
      ;(await notificationPermission()) === "denied" && onPermissionDenied?.()
    }
  }

  const removeNotificationListenerError = async () =>
    (await notificationPermission()) === "denied" && notification && onPermissionDenied?.()

  useEffect(() => {
    if (document.visibilityState !== "hidden" && notification) {
      notification.close()
      removeNotificationListenerError()
    }
  }, [document.visibilityState])

  return { handleNotification }
}
