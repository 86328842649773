import { format, parseISO } from "date-fns"
import { MedicationRequest, ServiceRequest } from "fhir"
import { confirmDialog } from "primereact/confirmdialog"

import { formatsByTypes } from "data"

import { MedicationRequestData } from "./types"

const getOrderDate = (order?: ServiceRequest) =>
  order?.occurrence?.dateTime || order?.occurrence?.Period || order?.authoredOn
    ? format(
        parseISO(
          (order?.occurrence?.dateTime ??
            order.occurrence?.Period?.end ??
            order.occurrence?.Period?.start ??
            order.authoredOn) as string,
        ),
        formatsByTypes.LONG_DATE,
      )
    : "unspecified"

const stopMR = (medReqId: string, onAcceptCliked: (mrId: string) => void) => {
  confirmDialog({
    message: "Are you sure you want to stop this prescription?",
    header: "Confirmation",
    acceptLabel: "Stop",
    rejectLabel: "Cancel",
    rejectClassName: "button-default",
    acceptClassName: "button-primary",
    accept: () => onAcceptCliked(medReqId),
  })
}

const refreshMR = (updatedMR: MedicationRequest, medicationsList: MedicationRequestData[]) => {
  const index = medicationsList?.findIndex((mrData) => mrData.medicationRequestInfo.id === updatedMR.id)

  if (index !== -1) {
    const updatedMedData = {
      ...medicationsList[index],
      ...{ medicationRequestInfo: { ...medicationsList[index].medicationRequestInfo, ...updatedMR } },
    }
    medicationsList.splice(index, 1, updatedMedData)
  }
}

const onCancel = (id: string, onAcceptCliked: (mrId: string) => void) => {
  confirmDialog({
    message: "Are you sure you want to cancel this order?",
    header: "Confirmation",
    acceptLabel: "Ok",
    rejectLabel: "Cancel",
    rejectClassName: "button-default",
    acceptClassName: "button-primary",
    accept: () => {
      onAcceptCliked(id)
    },
  })
}

const completeMR = (medReqId: string, onAcceptCliked: (mrId: string) => void) => {
  confirmDialog({
    message: "Are you sure you want to mark as completed this prescription?",
    header: "Confirmation",
    acceptLabel: "Complete",
    rejectLabel: "Cancel",
    rejectClassName: "button-default",
    acceptClassName: "button-primary",
    accept: () => onAcceptCliked(medReqId),
  })
}

export { getOrderDate, stopMR, refreshMR, onCancel, completeMR }
