import { Suspense, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Reference } from "fhir"

import { LoadingView } from "commons"
import { PatientSelection } from "patient"
import { useLoggedUserPatients } from "security"

import bg from "images/bg.webp"

const Home: React.FC = () => {
  document.title = "Patient Portal"
  const link = document.querySelector<HTMLLinkElement>("link[rel~='icon']")
  if (link) link.href = "/favicon.ico"
  document.body.removeAttribute("style")

  const navigate = useNavigate()
  const { patientRefs } = useLoggedUserPatients()

  const navToPatient = (patient: Reference) => {
    navigate(`/patient/${patient.id}/dashboard`)
  }

  useEffect(() => {
    if (patientRefs?.length === 1) navToPatient(patientRefs?.[0])
  }, [])

  return (
    <Suspense fallback={<LoadingView />}>
      {patientRefs?.length > 1 && (
        <div className="flex items-center justify-center w-full h-full bg-white">
          <img alt="bg" src={bg} className="object-cover h-full w-full" />

          <div className="flex flex-col justify-center absolute bg-white min-w-[75%] md:min-w-[50%] lg:min-w-[40%] xl:min-w-[30%] rounded-xl shadow-sm shadow-gray-600">
            <div className="font-bold bg-gray-50 rounded-t-xl h-16 flex items-center justify-center">
              Patient practice selection
            </div>
            <div className="px-6 lg:px-20 pb-6 pt-5 md:pb-10 md:pt-10">
              <div className="mt-8 xl:mt-14 w-full">
                <PatientSelection navToPatient={navToPatient} />
              </div>
            </div>
            <div className="flex mb-3 justify-center">
              <a
                title="Policy & Terms"
                href="https://www.chartedhealth.com/156-2/"
                rel="noreferrer"
                target="_blank"
                className="text-blue-400 font-light hover:text-blue-500/90 hover:underline"
              >
                Privacy Policy & Terms of usage
              </a>
            </div>
          </div>
        </div>
      )}
    </Suspense>
  )
}

export { Home }
