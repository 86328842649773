import { faCalendarClock, faSearch } from "@fortawesome/pro-regular-svg-icons"
import { faCheck, faStop } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { MedicationKnowledge, Money } from "fhir"
import { Checkbox } from "primereact/checkbox"
import { ConfirmDialog } from "primereact/confirmdialog"
import { MenuItem } from "primereact/menuitem"
import { SelectButton } from "primereact/selectbutton"
import { startTransition, useId, useMemo, useReducer, useState } from "react"
import InfiniteScroll from "react-infinite-scroller"

import {
  Button,
  FloatingButton,
  GroupedList,
  SearchWithStatus,
  SkeletonLoader,
  useChargeItemDefinitions,
  useSidebarContext,
} from "commons"
import { MedicationKnowledgeDetails, useMedicationRequestGroupByDate, useMedicationRequests } from "commons/meds"
import { useLoginContext } from "security"

import { completeMR, stopMR } from "../data"
import { useCompleteMedicationRequest, useRescheduleMedicationRequest, useStopMedicationRequest } from "../hooks"
import { Contents, MedicationRequestData } from "../types"
import RescheduleDialog from "./RescheduleDialog"
import { MedicationDataItem } from "./common"

const MedicationNextRefills = ({ activeContent, contentOptions, onUpdateContent }: Props) => {
  const { loggedInPatientId } = useLoginContext()
  const { organizationId } = useSidebarContext()
  const [selectedDates, setSelectedDates] = useState<string[]>([])
  const [showRescheduleDialog, setShowRescheduleDialog] = useState(false)
  const [itemActionClicked, onActionCliked] = useState("")
  const [selectedMK, setSelectedMK] = useState<MedicationKnowledge>()
  const [selectedMKPrice, setSelectedMKPrice] = useState<Money>()

  const { statusFilter, searchFilter, perPage, page, updateSearchText } = useReducerState()

  const {
    medicationRequests,
    medicationDispenses,
    medicationKnowledges,
    isLoading,
    medCodes,
    reloadMedications,
    fetchNextPage,
    hasNextPage,
  } = useMedicationRequests(loggedInPatientId as string, "nutraceutical", statusFilter, perPage, page, searchFilter)
  const { chargeItemDefinitions } = useChargeItemDefinitions(organizationId, {
    billToPracticeOrInsuranceCIDs: medCodes,
  })

  const { medicationRequestDataGroupByDate: mrDateGroups } = useMedicationRequestGroupByDate(
    medicationRequests,
    medicationKnowledges,
    chargeItemDefinitions.billToPracticeOrInsuranceCIDs,
    medicationDispenses,
  )

  const { rescheduleMedicationRequest, isLoading: isRescheduling } = useRescheduleMedicationRequest(
    reloadMedications,
    () => {
      setShowRescheduleDialog(false)
      setSelectedDates([])
    },
  )

  const { stopMedicationRequest, isLoading: isStopping } = useStopMedicationRequest(
    () => reloadMedications(),
    () => onActionCliked(""),
  )
  const { completeMedicationRequest, isLoading: isCompleting } = useCompleteMedicationRequest(
    () => reloadMedications(),
    () => onActionCliked(""),
  )

  const handleSelect = (date: string, checked: boolean) => {
    if (checked) {
      setSelectedDates([...selectedDates, date])
    } else {
      setSelectedDates(selectedDates.filter((d) => d !== date))
    }
  }

  const selectedMrs = useMemo(() => {
    const groups = mrDateGroups.filter((mrg) => selectedDates.includes(mrg.key))
    return groups.reduce<string[]>((acc, g) => {
      const ids = g.items.map((item) => item.medicationRequestInfo.id as string) ?? []
      return [...acc, ...ids]
    }, [])
  }, [selectedDates, mrDateGroups])

  const handleReschedule = (rescheduleDate: string) => {
    rescheduleMedicationRequest({ medicationRequests: selectedMrs, rescheduleDate })
  }

  const stop = (medReqId: string) => {
    stopMR(medReqId, () => {
      onActionCliked(medReqId)
      stopMedicationRequest(medReqId)
    })
  }

  const complete = (medReqId: string) => {
    completeMR(medReqId, () => {
      onActionCliked(medReqId)
      completeMedicationRequest(medReqId)
    })
  }

  const getMRAllowedActions = (medicationData: MedicationRequestData) => {
    const status = medicationData.medicationRequestInfo.status

    const enableComplete = status === "active"
    const enableStop = status === "active"

    const mrActionsItems = [
      {
        label: "Complete",
        icon: <FontAwesomeIcon icon={faCheck} size="sm" className="mr-2" />,
        command: () => {
          complete(medicationData.medicationRequestInfo.id as string)
        },
        disabled: !enableComplete,
      },
      {
        label: "Stop",
        icon: <FontAwesomeIcon icon={faStop} size="sm" className="mr-2" />,
        command: () => {
          stop(medicationData.medicationRequestInfo.id as string)
        },
        disabled: !enableStop,
      },
    ] as MenuItem[]

    return mrActionsItems
  }

  const loaderKey = useId()
  const loader = () => (
    <SkeletonLoader
      key={loaderKey}
      repeats={4}
      loaderType="list"
      skeletonShape="rectangle"
      skeletonSize="6rem"
      extraLine
    />
  )

  return (
    <div className="w-full h-full bg-white flex flex-col p-3 lg:px-5 pb-0">
      <div className="flex flex-wrap justify-center md:inline-flex md:justify-between md:h-12 w-full mb-3">
        <SelectButton
          value={activeContent}
          options={contentOptions}
          optionLabel="name"
          optionValue="value"
          unselectable={false}
          onChange={(e) => onUpdateContent(e.value as Contents)}
          className="mb-3 md:mb-0 w-full md:w-auto inline-flex md:block sm-buttons-w-full"
        />
        <div className="flex gap-4 md:w-1/3 xl:max-w-max justify-end">
          <SearchWithStatus
            placeholder="Search products"
            onSearch={(filter) => {
              startTransition(() => {
                updateSearchText(filter ?? "")
              })
            }}
            className="w-full"
          />
          <Button
            label="Reschedule"
            icon={faCalendarClock}
            className="md:hidden h-[42px]"
            onClick={() => setShowRescheduleDialog(true)}
            disabled={selectedDates.length === 0}
          />
        </div>
      </div>
      {isLoading ? (
        <div className="flex flex-1 flex-col overflow-auto">
          <SkeletonLoader
            repeats={3}
            containerClassName="p-5 overflow-auto"
            loaderType="list"
            skeletonShape="rectangle"
            skeletonSize="6rem"
            extraLine
          />
        </div>
      ) : mrDateGroups.length === 0 ? (
        <div className="flex flex-col items-center justify-center h-full">
          <FontAwesomeIcon icon={faSearch} size="3x" className="text-slate-400" />
          <h3 className="mt-2 text-sm font-semibold text-gray-900">No products found</h3>
        </div>
      ) : (
        <div className="flex flex-1 flex-col overflow-auto">
          <InfiniteScroll hasMore={hasNextPage} loadMore={() => fetchNextPage()} useWindow={false} loader={loader()}>
            <GroupedList
              className="grow"
              groups={mrDateGroups}
              renderItem={(medication) => (
                <MedicationDataItem
                  key={medication.medicationRequestInfo.id}
                  medicationKnowledge={medication.medicationKnowledge}
                  medicationRequest={medication.medicationRequestInfo}
                  medicationDispense={medication.medicationDispense}
                  pricePerUnit={medication.pricePerUnit}
                  showInstructions
                  showPackagingType
                  showStatus
                  showDispense
                  onClick={() => {
                    setSelectedMK(medication.medicationKnowledge)
                    setSelectedMKPrice(medication.pricePerUnit)
                  }}
                  dropdownMenuItems={getMRAllowedActions(medication)}
                  isDropdownLoading={
                    (isStopping || isCompleting) && itemActionClicked === medication.medicationRequestInfo.id
                  }
                />
              )}
              renderDivider={(key) => (
                <div className="sticky top-0 z-10 border-t border-b border-gray-200 bg-gray-50 py-1 text-sm font-medium text-gray-500">
                  <div className="flex items-center gap-2 px-2 py-1">
                    <Checkbox
                      inputId={key}
                      checked={selectedDates.includes(key)}
                      onChange={(e) => handleSelect(key, !!e.checked)}
                    />
                    <label htmlFor={key} className="text-base">
                      {key}
                    </label>
                  </div>
                </div>
              )}
              renderEmptyState={() => (
                <div className="text-center m-auto mt-20">
                  <FontAwesomeIcon icon={faSearch} size="3x" className="text-slate-400" />
                  <h3 className="mt-2 text-sm font-semibold text-gray-900">No laboratory found</h3>
                </div>
              )}
            />
          </InfiniteScroll>
        </div>
      )}
      <FloatingButton
        label="Reschedule"
        icon={faCalendarClock}
        className="hidden md:block fixed bottom-6 right-6"
        onClick={() => setShowRescheduleDialog(true)}
        disabled={selectedDates.length === 0}
      />

      {showRescheduleDialog && (
        <RescheduleDialog
          onHide={() => {
            setShowRescheduleDialog(false)
          }}
          onReschedule={handleReschedule}
          title="Reschedule prescriptions"
          isLoading={isRescheduling}
        />
      )}
      <ConfirmDialog />
      <MedicationKnowledgeDetails
        selectedMK={selectedMK}
        onHide={() => setSelectedMK(undefined)}
        pricePerUnit={selectedMKPrice}
      />
    </div>
  )
}

type Props = {
  activeContent: Contents
  contentOptions: { name: string; value: Contents }[]
  onUpdateContent(activeContent: Contents): void
}

type State = {
  statusFilter: Array<string>
  searchFilter?: string
  perPage: number
  page: number
  itemActionClicked: string
}

const initialState = {
  statusFilter: ["active", "draft", "on-hold"],
  searchFilter: undefined,
  perPage: 20,
  page: 1,
  itemActionClicked: "",
} as State

const reducer = (
  state: State,
  {
    type,
    payload,
  }: {
    type: "action-cliked" | "active-filters" | "change-per-page" | "change-page" | "update-search-filter"
    payload: Array<string> | number | string | MedicationRequestData | undefined
  },
) => {
  switch (type) {
    case "action-cliked":
      return { ...state, itemActionClicked: payload as string }
    case "active-filters":
      return {
        ...state,
        statusFilter: payload as string[],
      }
    case "change-per-page":
      return {
        ...state,
        perPage: payload as number,
      }
    case "change-page":
      return {
        ...state,
        page: payload as number,
      }
    case "update-search-filter":
      return {
        ...state,
        searchFilter: payload as string,
      }
    default:
      return state
  }
}

const useReducerState = () => {
  const [{ itemActionClicked, statusFilter, searchFilter, perPage, page }, dispatch] = useReducer(reducer, initialState)

  const onActionCliked = (medicationId: string) => {
    dispatch({ type: "action-cliked", payload: medicationId })
  }

  const updateFilters = (filters: string[]) => {
    dispatch({ type: "active-filters", payload: filters })
  }

  const changePerPage = (perPage: number) => {
    dispatch({ type: "change-per-page", payload: perPage })
  }

  const changePage = (page: number) => {
    dispatch({ type: "change-page", payload: page })
  }

  const updateSearchText = (filter: string) => {
    dispatch({ type: "update-search-filter", payload: filter })
  }

  return {
    itemActionClicked,
    statusFilter,
    searchFilter,
    perPage,
    page,
    onActionCliked,
    updateFilters,
    updateSearchText,
    changePerPage,
    changePage,
  }
}

export { MedicationNextRefills }
