import { PaymentView } from "@chartedhealth/payment-components"
import { useSearchParams } from "react-router-dom"
import { FC, Suspense } from "react"

import { LoadingView } from "commons"

const PaymentDetailsContainer: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const paymentId = searchParams.get("paymentId")

  const showInvoiceDetails = (invoiceId: string) => {
    searchParams.delete("paymentId")
    searchParams.set("invoiceId", invoiceId)

    setSearchParams(searchParams)
  }

  if (!paymentId) {
    return null
  }

  return (
    <div className="relative h-screen w-full flex flex-col overflow-auto bg-white">
      <Suspense fallback={<LoadingView />}>
        <PaymentView paymentId={paymentId} showInvoiceDetails={showInvoiceDetails} />
      </Suspense>
    </div>
  )
}

export { PaymentDetailsContainer }
