import { StackedListItem, StackedListItemProps } from "commons"

import { MenuItem } from "primereact/menuitem"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendar, faPrint } from "@fortawesome/pro-solid-svg-icons"
import { parseISO } from "date-fns"

import { formatDate, getBadgeColor } from "utils"
import { formatsByTypes } from "data"

import { MetabolicCodeReportData } from "../types"
import { useMetabolicCodeReportUrl } from "../hooks"

const MetabolicCodeReportItem = ({ report }: Props) => {
  const { getReportUrl, isLoadingUrl } = useMetabolicCodeReportUrl((reportUrl) => {
    const tab = window.open()
    if (tab && reportUrl) {
      tab.location.href = reportUrl
      tab.focus()
    }
  })

  const showResult = (carePlanId: string) => {
    getReportUrl({ carePlanId })
  }

  return <StackedListItem modelData={reportItemModel(report, showResult, isLoadingUrl)} />
}

const reportItemModel = (
  data: MetabolicCodeReportData,
  showResult: (planId: string) => void,
  loading: boolean,
): StackedListItemProps => {
  const getMenuItems = () => {
    const menuItems: MenuItem[] = [
      {
        icon: <FontAwesomeIcon icon={faPrint} size="sm" className="mr-1" />,
        label: "View results",
        disabled: !data.carePlan.id,
        command: () => {
          showResult(data.carePlan.id as string)
        },
      },
    ]

    return menuItems
  }

  const issuedDate = formatDate(parseISO(new Date(data.issued as Date).toISOString()), formatsByTypes.LONG_DATE)

  return {
    leftData: [
      {
        lineItems: [
          { name: "Name", value: `Metabolic Code Report - ${issuedDate}` },
          {
            name: "Issued",
            value: issuedDate,
            icon: faCalendar,
          },
        ],
      },
    ],
    badge: getBadgeColor(data.status),
    menu: getMenuItems(),
    isLoading: loading,
    itemClassName: "items-center",
  }
}

type Props = { report: MetabolicCodeReportData }

export { MetabolicCodeReportItem }
