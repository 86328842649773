import { faChevronDown, faChevronUp, faSquareArrowDown } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { codeableConceptAsString } from "fhir"
import { classNames } from "primereact/utils"
import { useState } from "react"
import { parseISO, format } from "date-fns"
import {
  faBuilding,
  faCalendarDays,
  faChartMixed,
  faNoteMedical,
  faSquarePollVertical,
} from "@fortawesome/pro-regular-svg-icons"

import { formatsByTypes } from "data"
import { Button } from "commons"
import { strCapitalize } from "utils"

import { LabPanelDetailsComponent } from "../types"
import { useShowDocument } from "../hooks"

const LabPanelDetails = ({ panel }: Props) => {
  const [expanded, setExpanded] = useState(false)
  const results = panel.results
  const { showDocument, isLoading } = useShowDocument()
  const hasFinalResults = results?.length && results?.every(({ status }) => status === "final")

  const onClick = () => setExpanded(!expanded)

  return (
    <div
      className={classNames("flex flex-col p-4 transition-all ease-in-out duration-200", {
        "cursor-pointer": panel.totalResults,
      })}
      onClick={panel.totalResults ? onClick : undefined}
    >
      <div className="flex flex-row justify-between items-baseline">
        <div className="flex flex-row gap-2 items-baseline">
          <span
            className={classNames("rounded-full w-2 h-2", hasFinalResults ? "bg-green-500" : "bg-yellow-500")}
          ></span>
          <span className="font-medium text-gray-900 break-words whitespace-normal text-wrap">
            {codeableConceptAsString(panel.code ?? panel.key)}
          </span>
        </div>
        <div className={classNames("flex gap-4", { "text-gray-300": !panel.totalResults })}>
          <span className="text-sm text-gray-300">Results: {panel.totalResults ?? 0}</span>
          <FontAwesomeIcon icon={expanded ? faChevronUp : faChevronDown} />
        </div>
      </div>
      <div className={classNames("flex flex-wrap p-4 gap-8", { hidden: !expanded })}>
        {results?.map((observation) => {
          return (
            <div key={observation.key} className="w-full flex flex-col text-sm text-gray-500 whitespace-nowrap">
              <div className="flex space-x-6 items-center">
                <span title="Code" className="font-semibold">
                  {observation.code} {!observation?.value && observation?.unit && `(${observation.unit})`}
                </span>
                {observation?.attachment?.url && (
                  <Button
                    className="p-button-sm p-button-text py-0.5 px-1 text-xs bg-gray-100"
                    label="Download"
                    buttonStyle="text"
                    size="xs"
                    icon={faSquareArrowDown}
                    iconSize="text-sm"
                    onClick={() => {
                      showDocument(observation?.attachment?.url)
                    }}
                    loading={isLoading}
                  />
                )}
              </div>

              {observation?.referenceRange && (
                <span title="Reference range" className="flex items-center gap-1">
                  <FontAwesomeIcon icon={faChartMixed} />
                  {`(${observation.referenceRange} ${observation.unit})`}
                </span>
              )}

              {observation.effectiveDateTime && (
                <span title="Effective date" className="flex items-center gap-1">
                  <FontAwesomeIcon icon={faCalendarDays} />
                  {format(parseISO(observation.effectiveDateTime), formatsByTypes.SHORT_DATETIME_WITH_TIMEZONE)}
                </span>
              )}
              {!!observation?.value && (
                <span title="Value" className="flex items-center gap-1">
                  <FontAwesomeIcon icon={faSquarePollVertical} />
                  {`${observation.value} ${observation.unit}`}
                </span>
              )}
              {(observation.status !== "final" || !!observation.interpretation) && (
                <span
                  title={observation?.interpretation ? "Value interpretation" : "Status"}
                  className="flex items-center gap-1"
                >
                  <FontAwesomeIcon icon={faNoteMedical} />
                  {observation?.interpretation ?? strCapitalize(observation.status ?? "unspecified")}
                </span>
              )}
              {observation?.placeOfService && (
                <span
                  title="Place of service"
                  className="flex items-center gap-1 underline hover:text-primary-hover cursor-pointer"
                  onClick={() => observation.scrollToPosRef(observation.placeOfService as string)}
                >
                  <FontAwesomeIcon icon={faBuilding} />
                  {observation?.placeOfService}
                </span>
              )}
              {!!observation.notes?.length && (
                <div className="w-full flex flex-col gap-1 mt-4 pt-2 border-t border-t-gray-200">
                  <span className="font-medium">Notes:</span>
                  <ul className="overflow-y-auto list-inside space-y-2">
                    {observation.notes?.map((note, index) => (
                      <li key={index} className="text-sm py-1 px-2 bg-gray-50 rounded-sm">
                        <pre>{note?.text}</pre>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

type Props = {
  panel: LabPanelDetailsComponent
}

export { LabPanelDetails }
