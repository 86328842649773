import { useQuery } from "@tanstack/react-query"
import {
  Invoice,
  MedicationDispense,
  MedicationKnowledge,
  MedicationRequest,
  ServiceRequest,
  Task,
  getResource,
  getResources,
} from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { getCommonCode, getMedCodes, hasMedAutoship } from "utils"

import { medsQueryKeys } from "../meds_query_keys"

const useMrOrderDetails = (patientId: string, orderId: string) => {
  const { search } = useClient()
  const queryKey = medsQueryKeys.orderDetails.details(orderId)

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        _query: "medication-order-details",
        _id: orderId,
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/ServiceRequest`, filters, signal })

      const serviceRequest = getResources<ServiceRequest>(bundle, "ServiceRequest")
      const medicationRequests = getResources<MedicationRequest>(bundle, "MedicationRequest")
      const medicationKnowledges = getResources<MedicationKnowledge>(bundle, "MedicationKnowledge")
      const tasks = getResources<Task>(bundle, "Task")
      const invoice = getResource<Invoice>(bundle, "Invoice")
      const medicationDispenses = getResources<MedicationDispense>(bundle, "MedicationDispense")

      return {
        serviceRequest: serviceRequest?.[0],
        tasks,
        medicationRequests,
        medicationKnowledges,
        invoice,
        medicationDispenses,
      }
    },
    throwOnError: true,
    meta: { context: { queryKey, orderId } },
  })

  const { missingInfoMessages, medCodes, medicationKnowledges } = useMemo(() => {
    const taskDescriptions =
      data?.invoice?.status !== "balanced"
        ? data?.tasks.reduce((acc, task) => {
            if (
              ["complete-shipping-address", "complete-shipping-method", "complete-cc"].includes(
                task.code?.coding?.[0].code ?? "",
              ) &&
              task.status === "ready"
            )
              return [...acc, task.description as string]
            return acc
          }, new Array<string>())
        : undefined

    const medicationKnowledges = data?.medicationKnowledges.reduce(
      (acc, mk) => {
        const code = getCommonCode(mk.code?.coding)
        return { ...acc, [code]: mk }
      },
      {} as Record<string, MedicationKnowledge>,
    )
    const medCodes = getMedCodes({ meds: data?.medicationRequests, withQty: true })

    return { missingInfoMessages: taskDescriptions, medCodes, medicationKnowledges }
  }, [data?.invoice?.status, data?.tasks, data?.medicationRequests, data?.medicationKnowledges])

  const hasAutoship = hasMedAutoship(data?.medicationRequests)

  return {
    serviceRequest: data?.serviceRequest,
    tasks: data?.tasks,
    medicationRequests: data?.medicationRequests,
    medicationKnowledges,
    invoice: data?.invoice,
    medicationDispenses: data?.medicationDispenses,
    missingInfoMessages,
    medCodes,
    isLoading,
    hasAutoship,
  }
}

export { useMrOrderDetails }
