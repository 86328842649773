import { useQuery } from "@tanstack/react-query"
import { Organization, Patient, getResources } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"

import { organizationKeys } from "../query-keys"

const usePatientOrgs = (patients: Record<string, Patient>) => {
  const { search } = useClient()
  const patientOrgsId = Object.values(patients).reduce(
    (acc, pat) => ({ ...acc, [pat.managingOrganization?.id as string]: pat.id as string }),
    {} as Record<string, string>,
  )

  const queryKey = organizationKeys.patientOrgs(Object.keys(patientOrgsId))

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async () => {
      const filters = new URLSearchParams({
        _id: Object.keys(patientOrgsId).join(","),
        _elements: "name, address, active",
      })
      const bundle = await search({ endpoint: "Organization", filters })

      const organizations = getResources<Organization>(bundle, "Organization")

      return { organizations }
    },
    meta: { context: { queryKey } },
  })

  const patientOrgs = useMemo(
    () =>
      data?.organizations.reduce(
        (acc, org) => {
          const pat = patientOrgsId[org.id ?? ""]

          if (pat && org.id) return { ...acc, [pat]: org }

          return acc
        },
        {} as Record<string, Organization>,
      ),
    [data],
  )

  return { patientAndOrgs: patientOrgs, isLoading }
}

export { usePatientOrgs }
