import { Duration } from "fhir"
import { FC, useMemo } from "react"

import { ActionGroupCode, CpoeRequest } from "../types"
import { OrderListItem } from "./OrderListItem"

const CheckoutSection: FC<Props> = ({
  label,
  requests,
  sectionRequestsType,
  editableRequests,
  onChangeMed,
  onDelete,
}: Props) => {
  const filteredRequests = useMemo(
    () => requests.filter((req) => req.type === sectionRequestsType),
    [requests, sectionRequestsType],
  )

  if (!filteredRequests.length) return null

  return (
    <div className="w-full">
      <h3 className="font-bold pl-3">{label}</h3>
      <div className="space-y-3">
        <div className="flex flex-col">
          {filteredRequests.map((item, index) => (
            <OrderListItem
              key={item.resource.resource?.id ?? index}
              medRequest={item}
              onChange={(field, value) => onChangeMed?.(field, value, item.resource.resource?.id)}
              editable={!!editableRequests}
              onDelete={onDelete}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

type Props = {
  label: string
  requests: CpoeRequest[]
  sectionRequestsType: ActionGroupCode
  editableRequests?: boolean
  onChangeMed?(field: string, value: boolean | Duration, id?: string): void
  onDelete?(requestId: string): void
}

export { CheckoutSection }
