import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRotateLeft, faSignOut } from "@fortawesome/pro-light-svg-icons"
import { FC } from "react"
import { Button } from "primereact/button"

import { CustomError } from "commons"

import { ERROR_CAUSE } from "../types"

const AuthError: FC<Props> = ({ error, logout, shouldRetry }) => {
  const message = error.cause?.message ?? ERROR_CAUSE.UNAUTHORIZED
  const code = error.cause?.name ?? "Auth Error"

  const handleRetry = () => {
    window.location.reload()
  }

  return (
    <div className="flex items-center justify-center w-full h-full">
      <div className="text-center">
        <p className="p-error text-5xl">{code}</p>
        <h1 className="text-4xl py-2 font-bold">{error.message}</h1>
        <p className="text-gray-400 pt-2 pb-4">{message}</p>
        <div className="flex items-center justify-center gap-4">
          {shouldRetry && (
            <Button onClick={handleRetry} className="p-button button-default">
              <span className="flex items-center">
                <FontAwesomeIcon icon={faArrowRotateLeft} className="mr-2" /> Retry
              </span>
            </Button>
          )}
          <Button onClick={logout} className="p-button button-primary">
            <span className="flex items-center">
              <FontAwesomeIcon icon={faSignOut} className="mr-2" /> Logout
            </span>
          </Button>
        </div>
      </div>
    </div>
  )
}

type Props = { error: CustomError; logout(): void; shouldRetry?: boolean }

export { AuthError }
