import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useClient } from "api"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"
import { CustomError } from "commons"

import { coverageKeys } from "../query-keys"

const useDeleteCoverage = (hideForm: () => void) => {
  const { remove } = useClient()
  const queryClient = useQueryClient()

  const removeFn = async (coverageId: string) => remove("Coverage", coverageId)

  const { mutate: deleteCoverage, isPending: isDeleting } = useMutation({
    mutationFn: removeFn,
    onError: (error: CustomError, id) => {
      displayNotificationError(registerErrorTrace(error, { coverageId: id }))
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: coverageKeys.all })

      displayNotificationSuccess("Insurance deleted successfully!")
    },
    onSettled: hideForm,
  })

  return { deleteCoverage, isDeleting }
}

export { useDeleteCoverage }
