import { FormikValues } from "formik"
import { PropsWithChildren } from "react"

import { FormContainer, FormContainerProps } from "./FormContainer"
import { Slideover, SlideoverProps } from "../components/Slideover"

const SlideFormContainer = <T extends FormikValues>({
  showSlide,
  title,
  onCancel,
  showCloseIcon = true,
  dismissable = true,
  children,
  ...formProps
}: PropsWithChildren<Props<T>>) => (
  <Slideover
    showSlide={showSlide}
    title={title}
    showCloseIcon={showCloseIcon}
    dismissable={dismissable}
    onHide={onCancel ?? (() => {})}
  >
    <FormContainer onCancel={onCancel} {...formProps} enableReinitialize>
      {children}
    </FormContainer>
  </Slideover>
)

type Props<T extends FormikValues> = Omit<
  FormContainerProps<T>,
  "enableReinitialize" | "className" | "title" | "subTitle"
> &
  Omit<SlideoverProps, "onHide">

export { SlideFormContainer }
