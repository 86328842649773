import { useClient } from "api"
import { displayNotificationError } from "errors"
import { CustomError } from "commons"

const useShowDocument = () => {
  const { getSignedUrl } = useClient()
  let isLoading = true

  const showDocument = async (url?: string) => {
    if (url) {
      const tab = window.open()
      const fullUrl = url.startsWith("/") ? url.slice(1) : url
      const signedUrl = await getSignedUrl(fullUrl)
      if (tab) {
        tab.location.href = signedUrl.url
        tab.focus()
      }
      isLoading = false
      return true
    } else {
      isLoading = false
      displayNotificationError(new Error("Wrong document url") as CustomError)
      return false
    }
  }

  return { showDocument, isLoading }
}

export { useShowDocument }
