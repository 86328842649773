import { useMutation } from "@tanstack/react-query"
import { Parameters, ParametersParameterArrayArray } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"

import { TDicc, TKeys } from "../types"

export type THJoinVideoCall = {
  sessionId: string
  patientId: string
}

export type THRejectVideoCall = THJoinVideoCall & {
  practitionerId: string
}

type TParameters = {
  sessionId?: string
  practitionerId?: string
  resourceType?: "Parameters"
}

type TUseVideoCall = {
  join: (keysDicc: KeysDiccType) => void
  reject?: () => void
  finish?: () => void
}

type TSetP = keyof Omit<TParameters, "resourceType">
type TSetPKey = { [key in TSetP]?: string }

const endpoint = "Patient"

const operation = (op: "join" | "reject" | "finish") => `${op}-video-call`

const onError = (error: CustomError, context: unknown) => displayNotificationError(registerErrorTrace(error, context))

const setParameter = (obj: TSetPKey) => ({
  name: Object.entries(obj).map(([key, value]) => value && key)[0],
  value: { string: Object.values(obj)[0] },
})

const parameters = ({ sessionId, practitionerId, resourceType = "Parameters" }: TParameters): Parameters => {
  const parameter = []
  sessionId && parameter.push(setParameter({ sessionId }))
  practitionerId && parameter.push(setParameter({ practitionerId }))
  return { parameter, resourceType }
}

export const useVideoCall = ({ join, reject, finish }: TUseVideoCall) => {
  const { operationRequest } = useClient()

  const handleJoinVideoCall = async ({ patientId, sessionId }: THJoinVideoCall) => {
    const keys: TKeys = await operationRequest({
      endpoint,
      method: "POST",
      operation: operation("join"),
      id: patientId,
      parameters: parameters({ sessionId }),
    })
    return Dicc<ParametersParameterArrayArray[]>(keys.parameter)
  }

  const { mutate: joinVideoCall, isPending: joinIsPending } = useMutation({
    mutationFn: handleJoinVideoCall,
    onError,
    onSuccess: (keysDicc) => join?.(keysDicc),
  })

  const handleRejectVideoCall = async ({ practitionerId, sessionId, patientId }: THRejectVideoCall) =>
    await operationRequest({
      endpoint,
      method: "POST",
      operation: operation("reject"),
      id: patientId,
      parameters: parameters({ sessionId, practitionerId }),
    })

  const { mutate: rejectVideoCall, isPending: rejectIsPending } = useMutation({
    mutationFn: handleRejectVideoCall,
    onError,
    onSuccess: () => reject?.(),
  })

  const handleFinishVideoCall = async ({ patientId, sessionId }: THJoinVideoCall) =>
    await operationRequest({
      endpoint,
      method: "POST",
      operation: operation("finish"),
      id: patientId,
      parameters: parameters({ sessionId }),
    })

  const { mutate: finishVideoCall, isPending: finishIsPending } = useMutation({
    mutationFn: handleFinishVideoCall,
    onError,
    onSuccess: () => finish?.(),
  })

  return { joinVideoCall, joinIsPending, rejectVideoCall, rejectIsPending, finishVideoCall, finishIsPending }
}

const Dicc: TDicc = (toSort) => toSort.reduce((acc, cur) => ({ ...acc, [cur.name]: cur }), {})

export type KeysDiccType = {
  sessionId?: ParametersParameterArrayArray
  accessToken?: ParametersParameterArrayArray
  applicationId?: ParametersParameterArrayArray
}
