import { useContext } from "react"

import { VideoCallContext } from "../context"

const useVideoCallContext = () => {
  const context = useContext(VideoCallContext)

  if (context === undefined) {
    throw new Error("useVideoCallContext must be used within a VideoCallProvider")
  }

  return context
}

export { useVideoCallContext }
