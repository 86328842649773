import { Message } from "primereact/message"
import { useEffect } from "react"
import { useLocation } from "react-router-dom"

import { SkeletonLoader, useSidebarContext } from "commons"
import { useLoginContext } from "security"

import { CPOERequestsContextProvider } from "../context/CPOERequestsProvider"
import { useCPOEContext, useCpoeOrders } from "../hooks"
import { CartContainer } from "./cart"
import { OrdersContainer } from "./OrdersContainer"

const CheckoutView = () => {
  const location = useLocation()
  const { isStripeSetupComplete, organizationId } = useSidebarContext()
  const { loggedInPatientId } = useLoginContext()
  const { refreshCpoeCount, isLoadingOrdersCount, ordersCount, gotoShop } = useCPOEContext()

  useEffect(() => refreshCpoeCount(), [])

  useEffect(() => {
    if (!isLoadingOrdersCount && ordersCount === 0) gotoShop()
  }, [isLoadingOrdersCount, ordersCount])

  const { cpoeRequests, requestGroup, activityDefinition, isLoading } = useCpoeOrders(
    organizationId,
    loggedInPatientId,
    ordersCount,
  )

  if (!isStripeSetupComplete)
    return <Message severity="warn" text="Purchases are currently disabled please contact your practitioner." />

  return (
    <div className="h-full flex flex-col p-2 lg:px-3">
      {isLoadingOrdersCount || isLoading ? (
        <SkeletonLoader repeats={5} loaderType="two-lines" />
      ) : (
        <CPOERequestsContextProvider
          cpoeRequests={cpoeRequests}
          isLoadingRequests={isLoading}
          ordersCount={ordersCount}
          activityDefinition={activityDefinition}
          requestGroup={requestGroup}
        >
          {location.pathname.includes("/shop") ? <CartContainer /> : <OrdersContainer />}
        </CPOERequestsContextProvider>
      )}
    </div>
  )
}

export { CheckoutView }
