import { faUser } from "@fortawesome/pro-light-svg-icons"
import { faHouseCrack } from "@fortawesome/pro-regular-svg-icons"
import { faPlus } from "@fortawesome/pro-solid-svg-icons"
import { Coverage } from "fhir"

import {
  Button,
  ConfirmDialog,
  DataContainerSlideoverForm,
  InformationCardContainer,
  SkeletonLoader,
  StackedListContainer,
  StackedListItemProps,
  useCrudReducer,
} from "commons"
import { ProfileSection } from "data"
import { useLoginContext } from "security"
import { getBadgeColor, strCapitalize } from "utils"
import { defaultEditRemoveMenu } from "utils-renders"

import { useCoverages, useCreateCoverage, useDeleteCoverage } from "../hooks"
import { CoverageForm } from "./CoverageForm"
import { coverageValidationSchema, getCoverageInitialValues, sanitizeCoverage } from "./validations"

const CoveragesContainer = () => {
  const { loggedInPatientId } = useLoginContext()

  const { showSlide, initialValue, deleteIndex, add, edit, reset, setDeleteIndex } = useCrudReducer({
    defaultEntity: getCoverageInitialValues(loggedInPatientId),
  })

  const { coverages, isLoading } = useCoverages(loggedInPatientId)
  const { createCoverage, isAdding } = useCreateCoverage(reset)
  const { deleteCoverage, isDeleting } = useDeleteCoverage(() => setDeleteIndex(undefined))

  const onSubmit = (data: Coverage) => createCoverage(sanitizeCoverage(data))

  return (
    <InformationCardContainer
      id={ProfileSection.INSURANCE}
      title="Insurance Information"
      showEdit={coverages.length > 0}
      customButton={<Button buttonStyle="default" label="Add new" icon={faPlus} onClick={add} />}
      className="profile-card-section"
    >
      {isLoading ? (
        <SkeletonLoader repeats={1} loaderType="two-lines" />
      ) : (
        <DataContainerSlideoverForm
          hasData={!!coverages?.length}
          showSlide={showSlide || isAdding}
          formTitle="Insurance"
          iconDataNotFound={faHouseCrack}
          formInitialValue={initialValue}
          validationSchema={coverageValidationSchema}
          onSubmit={onSubmit}
          onCancel={reset}
          form={<CoverageForm />}
          showAddButton={false}
          onButtonAddClick={add}
        >
          <StackedListContainer
            data={coverages ?? []}
            itemModelBuilder={(item) =>
              coverageModelBuilder(
                item,
                () => edit(item),
                () => setDeleteIndex(item.id),
              )
            }
          />
          <ConfirmDialog
            confirmText="Are you sure you want to remove this insurance?"
            actionName="Remove"
            visible={deleteIndex !== undefined || isDeleting}
            isLoading={isDeleting}
            onConfirm={() => deleteCoverage(deleteIndex as string)}
            hideDialog={() => setDeleteIndex(undefined)}
          />
        </DataContainerSlideoverForm>
      )}
    </InformationCardContainer>
  )
}

const coverageModelBuilder = (coverage: Coverage, onEdit: () => void, onDelete: () => void): StackedListItemProps => ({
  leftData: [
    {
      lineItems: [{ name: "Type", value: strCapitalize(coverage.type?.coding?.[0].display as string) }],
    },
    ...(coverage.payor[0].display
      ? [
          {
            lineItems: [
              {
                name: "Subscriber",
                value: coverage.beneficiary.id === coverage.subscriber?.id ? "Self" : coverage.subscriber?.display,
                icon: faUser,
              },
              {
                name: "Subscriber ID",
                value: `#${coverage.subscriberId}`,
              },
            ],
          },
        ]
      : []),
  ],
  badge: getBadgeColor(coverage.status),
  menu: defaultEditRemoveMenu({ onEdit, onDelete }),
})

export { CoveragesContainer }
