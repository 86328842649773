import { SizeProp } from "@fortawesome/fontawesome-svg-core"
import {
  faDownLeftAndUpRightToCenter,
  faMicrophone,
  faMicrophoneSlash,
  faPhoneArrowDown,
  faScreencast,
  faVideo,
  faVideoSlash,
  faWarning,
  faWifiSlash,
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Dialog } from "primereact/dialog"
import { useEffect, useState } from "react"
import { createRoot } from "react-dom/client"

import { useCheckNetworkContext } from "check-network"

import { IdToAppendVideo, SettingsBtnsNames } from "../data"
import { useVideoCallContext } from "../hooks"
import { TSettings } from "../types"
import { handleF5KeyFunc } from "../utils"
import { AlertBeforeUnload } from "./AlertBeforeUnload"
import { NotificationVideoCall } from "./NotificationVideoCall"
import { SettingsBtns, TIconSettingBtn, TSettingsBtnMode } from "./SettingsBtns"

export const VideoCall = () => {
  const {
    isInterviewStarted,
    handleStopStreaming,
    videoStates,
    toggleAudio,
    toggleVideo,
    handlePictureInPicture,
    isPictureInPictureActive,
    notificationType,
    subscriberSettings,
    isSubscriberConnected,
    shareScreen,
  } = useVideoCallContext()

  return (
    <>
      <NotificationVideoCall type={notificationType} />
      <MeetingCall
        shareScreen={{
          onClick: shareScreen,
          onIt: !!videoStates?.shareScreen,
          subcriberOnIt: subscriberSettings?.shareScreen,
        }}
        hasSubscriber={!!videoStates?.subscriber}
        onHide={handleStopStreaming}
        visible={isInterviewStarted && !isPictureInPictureActive}
        showSettings={!!videoStates?.publisher}
        subscriberHasAudio={subscriberSettings.audio}
        isSubscriberConnected={isSubscriberConnected}
        vonageSettings={{ audio: toggleAudio, video: toggleVideo, pip: handlePictureInPicture }}
      />
    </>
  )
}

const shareScreenRoot = createRoot(document.getElementById("settingsShareScreenBtn")!)

const MeetingCall = ({
  onHide,
  visible,
  vonageSettings,
  showSettings = true,
  subscriberHasAudio,
  hasSubscriber,
  isSubscriberConnected,
  shareScreen,
  beforeUnloadIsLoading,
}: TMeetingCall) => {
  const initialSettings = { audio: false, video: false }
  const [settings, setSettings] = useState<TSettings>(initialSettings)
  const { audio, video, pip } = vonageSettings
  const {
    warningBeforeUnload,
    setWarningBeforeUnload,
    browserNotSupportedPIP,
    destroyedSession,
    recieveVC,
    browserNotSupportedShareScreen,
  } = useVideoCallContext()
  const { isOnline } = useCheckNetworkContext()

  useEffect(() => {
    destroyedSession && handleHide()
  }, [destroyedSession])

  useEffect(() => {
    !isOnline && handleHide()
  }, [isOnline])

  useEffect(() => {
    visible && handleF5KeyFunc({ listen: true, action: () => setWarningBeforeUnload(true) })
  }, [visible])

  const handleVideo = () => {
    video(settings.video)
    setSettings((prev) => ({ ...prev, video: !prev.video }))
  }
  const handleAudio = () => {
    audio(settings.audio)
    setSettings((prev) => ({ ...prev, audio: !prev.audio }))
  }

  const handleHide = () => {
    onHide()
    setSettings(initialSettings)
  }

  const proceedAlertBeforeUnload = () => {
    handleF5KeyFunc({ listen: false, action: () => setWarningBeforeUnload(true) })
    handleHide()
  }

  const handlePip = () => pip()

  const subscriber = `${shareScreen?.subcriberOnIt ? "size-0 md:size-40" : "size-32 md:size-40 border-2 border-gray-600"}  absolute right-0 top-0 z-10 overflow-hidden`

  const publisher = "border-2 border-gray-600 grow w-full h-max overflow-hidden flex self-start relative"

  const handleSettingsMode = (condition: boolean, mode: TSettingsBtnMode = "danger") => (condition ? mode : undefined)

  const browserNotAvaliable = "is not avaliable in current browser"

  const settingBtn: TIconSettingBtn[] = [
    {
      name: SettingsBtnsNames.endCall,
      mode: "danger",
      onClick: () => handleHide(),
      fontIcon: faPhoneArrowDown,
    },
    {
      name: SettingsBtnsNames.audio,
      mode: handleSettingsMode(settings.audio),
      onClick: handleAudio,
      fontIcon: !settings.audio ? faMicrophone : faMicrophoneSlash,
    },
    {
      name: SettingsBtnsNames.video,
      mode: handleSettingsMode(settings.video),
      onClick: handleVideo,
      fontIcon: !settings.video ? faVideo : faVideoSlash,
      disabled: shareScreen?.subcriberOnIt,
    },
    {
      name: browserNotSupportedShareScreen
        ? [SettingsBtnsNames.shareScreen, browserNotAvaliable].join(" ")
        : SettingsBtnsNames.shareScreen,
      onClick: shareScreen?.onClick,
      disabled: browserNotSupportedShareScreen || shareScreen?.subcriberOnIt || shareScreen?.onIt,
      fontIcon: browserNotSupportedShareScreen ? faWarning : faScreencast,
    },
    {
      name: browserNotSupportedPIP ? [SettingsBtnsNames.pip, browserNotAvaliable].join(" ") : SettingsBtnsNames.pip,
      onClick: handlePip,
      disabled: browserNotSupportedPIP || shareScreen?.subcriberOnIt || shareScreen?.onIt,
      fontIcon: browserNotSupportedPIP ? faWarning : faDownLeftAndUpRightToCenter,
    },
  ]

  useEffect(() => {
    shareScreenRoot.render(
      <SettingsBtns
        hide={!shareScreen?.onIt}
        settings={settingBtn}
        className={`w-fit fixed bottom-4 right-4 bg-gray-600 flex items-center justify-center p-4 gap-4 z-50 rounded-md`}
      />,
    )
  }, [shareScreen?.onIt, settings])

  return (
    <>
      <AlertBeforeUnload
        visible={warningBeforeUnload}
        proceed={proceedAlertBeforeUnload}
        proceedLoading={beforeUnloadIsLoading}
        cancel={() => setWarningBeforeUnload(false)}
      />
      <Dialog
        modal={!shareScreen?.onIt}
        onHide={() => ({})}
        closable={false}
        visible={visible}
        draggable={false}
        resizable={false}
        className={`${shareScreen?.onIt && "hidden"} rounded-t-lg size-5/6 top xl:max-w-[65%] 2xl:max-w-[50%]`}
        contentStyle={{ padding: "0px" }}
        contentClassName="bg-gray-700 size-full pb-2 flex flex-col justify-end items-center relative"
        headerClassName="bg-gray-600 text-white"
        header={<h2>TeleMedicine Call</h2>}
      >
        <div id={IdToAppendVideo.shareScreen} className={`${!shareScreen?.subcriberOnIt ? "hidden" : publisher}`}></div>
        <div
          id={IdToAppendVideo.subscriber}
          className={`${shareScreen?.subcriberOnIt ? subscriber : hasSubscriber ? `${publisher} duration-300` : "size-0 opacity-0"}`}
        >
          {hasSubscriber && !subscriberHasAudio && (
            <MicrophoneOff size={`${shareScreen?.subcriberOnIt ? "1x" : "2x"}`} />
          )}
        </div>
        <div
          id={IdToAppendVideo.publisher}
          className={`${shareScreen?.subcriberOnIt ? "hidden" : hasSubscriber ? subscriber : publisher} duration-300`}
        >
          {hasSubscriber && settings.audio && <MicrophoneOff size="1x" />}
        </div>
        <OffLineCallModal
          visible={!isSubscriberConnected}
          headerName={recieveVC?.practitionerFullName}
          onHide={() => handleHide()}
        />
        <SettingsBtns
          hide={!showSettings}
          settings={settingBtn}
          className={`w-full bg-gray-600 flex items-center justify-center p-6 gap-4 z-40`}
        />
      </Dialog>
    </>
  )
}

const MicrophoneOff = ({ size }: { size: SizeProp }) => (
  <FontAwesomeIcon
    size={size}
    className="absolute flex inset-0 z-10 ml-2 mt-3 font-semibold"
    color="#4b5563"
    icon={faMicrophoneSlash}
  />
)
const OffLineCallModal = ({
  visible,
  onHide,
  headerName,
}: {
  visible: boolean
  onHide: () => void
  headerName: string
}) => (
  <Dialog
    modal
    onHide={onHide}
    closable
    visible={visible}
    draggable={true}
    resizable={false}
    className="rounded-t-lg top xl:max-w-[65%] 2xl:max-w-[50%]"
    contentClassName="rounded-b-lg flex flex-col items-center px-8  w-8/9 h-8/9"
  >
    <FontAwesomeIcon icon={faWifiSlash} className="w-12 h-12" />
    <h1 className="text-2xl py-2 font-bold">{headerName} is disconnected</h1>
    <h3 className="text-sm text-gray-400 pt-2 pb-4">Please close and call again.</h3>
  </Dialog>
)

type TVonageSettings = {
  video: (state: boolean) => void | undefined
  audio: (state: boolean) => void | undefined
  pip: () => void
}

type TMeetingCall = {
  onHide: () => void
  visible: boolean
  showSettings?: boolean
  subscriberHasAudio: boolean
  vonageSettings: TVonageSettings
  hasSubscriber?: boolean
  isSubscriberConnected?: boolean
  beforeUnloadIsLoading?: boolean
  shareScreen?: { onClick: () => void; onIt: boolean; subcriberOnIt?: boolean }
}
