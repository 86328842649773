import { EMAIL_CONTACT_POINT_SYSTEM, PHONE_CONTACT_POINT_SYSTEM } from "./constants"
import {
  Bundle,
  CodeableConcept,
  ContactPoint,
  HumanName,
  isResourceObject,
  Reference,
  ResourceObject,
  Address,
  isPatient,
  isPractitioner,
  isOrganization,
  isRelatedPerson,
} from "./fhir"

const getResources = <T extends ResourceObject>(bundle: Bundle, resourceType?: string) =>
  bundle.entry?.reduce((acc, { resource }) => {
    if (isResourceObject(resource)) {
      if (resourceType) return resourceType === resource.resourceType ? [...acc, resource as T] : [...acc]
      else return [...acc, resource as T]
    }

    return acc
  }, [] as T[]) ?? []

const getResource = <T extends ResourceObject>(bundle: Bundle, resourceType: string) =>
  bundle.entry?.find(({ resource }) => isResourceObject(resource) && resource.resourceType === resourceType)
    ?.resource as T

const getResourcesByTypeAsIndex = <T extends ResourceObject>(
  bundle: Bundle,
  resourceType: string,
  getIndex?: (resource: T) => string | undefined,
) =>
  bundle.entry?.reduce((acc, { resource }) => {
    if (isResourceObject(resource) && resource.id && resource.resourceType === resourceType) {
      const index = getIndex?.(resource as T) ?? resource.id
      return { ...acc, [index]: resource as T }
    }

    return acc
  }, {} as Record<string, T>) ?? {}

const humanNameAsString = (name: HumanName | undefined): string => {
  if (!name) {
    return "unspecified"
  }

  if (name.text) {
    return name.text.trim()
  }

  const given = name?.given ? `${name?.given.join(" ")} ` : ""

  return `${given}${name.family}`
}

const getFirstEmail = (telecom: ContactPoint[] | undefined) => {
  const email = telecom?.find(({ system }) => system === EMAIL_CONTACT_POINT_SYSTEM)

  if (!email || !email?.value) {
    return "No email provided"
  }

  return email.value
}

const getFirstPhone = (telecom: ContactPoint[] | undefined) => {
  const phone = telecom?.find(({ system }) => system === PHONE_CONTACT_POINT_SYSTEM)

  if (!phone || !phone?.value) {
    return "No phone provided"
  }

  const matches = phone.value.match(/^(\d{3})-?(\d{3})-?(\d{4})$/)
  if (matches && matches.length === 4) return `(${matches[1]}) ${matches[2]}-${matches[3]}`

  return phone.value
}

const addressStringify = (address: Address | undefined) => {
  if (!address) return "Unspecified address"

  const { line, city, state, country, postalCode } = address

  return Array.from([line, city, state, country, postalCode])
    .flat()
    .filter((d) => d && d !== "")
    .join(", ")
}

const getHomeAddress = (address: Address[] | undefined) =>
  address?.find(({ use, type }) => use === "home" && type === undefined)

const getAddressByType = (address: Address[] | undefined, addressType: "postal" | "physical") =>
  address?.find(({ use, type }) => use === "home" && type === addressType)

const getShippingAddressIndex = (address: Address[] | undefined) =>
  address?.findIndex(({ use, type }) => use === "home" && type === "postal") ?? -1

const getStringAddressByType = (
  address: Address[] | undefined,
  addressType?: string,
  addressUse: "home" | "other" | "temp" = "home",
) => {
  const index = address?.findIndex(({ use, type }) => use === addressUse && type === addressType) ?? -1
  return getStringAddress(address?.[index])
}

const getStringAddress = (address?: Address) => {
  if (!address) {
    return "Unspecified address"
  }

  const { line, city, state, country, postalCode } = address ?? {}

  return Array.from([line, city, state, country, postalCode])
    .flat()
    .filter((d) => d && d !== "")
    .join(", ")
}

const getAddressIndexByType = (address: Address[] | undefined, addressType?: string) =>
  address?.findIndex(({ use, type }) => use === "home" && type === addressType) ?? -1

const codeableConceptAsString = (cc: CodeableConcept | undefined) => {
  if (!cc?.coding?.[0]?.code) {
    return cc?.text ?? "unspecified"
  }

  return cc.text ?? cc.coding?.[0]?.display ?? cc.coding?.[0]?.code
}

const asReference = (resource: ResourceObject): Reference => {
  let display

  if (isPatient(resource) || isPractitioner(resource) || isRelatedPerson(resource)) {
    display = humanNameAsString(resource.name?.[0])
  }

  if (isOrganization(resource)) {
    display = resource.name
  }

  return { id: resource.id, resourceType: resource.resourceType, ...(display ? { display } : {}) }
}

export {
  getResources,
  getResource,
  humanNameAsString,
  getFirstEmail,
  getFirstPhone,
  addressStringify,
  codeableConceptAsString,
  asReference,
  getResourcesByTypeAsIndex,
  getAddressByType,
  getAddressIndexByType,
  getHomeAddress,
  getShippingAddressIndex,
  getStringAddressByType,
}
