import { displayNotificationError } from "errors"
import { CustomError } from "commons"

const useShowDocument = () => {
  const showDocument = async (url?: string) => {
    if (url) {
      const tab = window.open()

      if (tab) {
        tab.location.href = url
        tab.focus()
      }
    } else {
      const error: CustomError = {
        name: "400",
        message: "Wrong document URL!",
        cause: { name: "400", message: "Wrong document URL!" },
      }
      displayNotificationError(error)
    }
  }

  return { showDocument }
}

export { useShowDocument }
