import { Address } from "fhir"
import { addressStringify, getAddressByType, getHomeAddress } from "fhir/utils"

import { AddressField } from "commons"
import { ProfileSection, emptyAddress } from "data"
import { useLoginContext } from "security"
import { getAddressSchema } from "utils"

import { useUpdatePatientAddress } from "../hooks"
import { InformationCard } from "./InformationCard"

const AddressInformation = () => {
  const { loggedInPatient } = useLoginContext()
  const { updateAddress, isUpdating } = useUpdatePatientAddress()

  const { address: patientAddress } = loggedInPatient

  const onSubmit = (address: Address) => {
    updateAddress({ address, patient: loggedInPatient })
  }

  const homeAddress = getHomeAddress(patientAddress)
  const shippingAddress = getAddressByType(patientAddress, "postal")
  const physicalAddress = getAddressByType(patientAddress, "physical")

  return (
    <InformationCard
      id={ProfileSection.ADDRESS}
      title="Address Information"
      data={{
        Home: addressStringify(homeAddress),
        Shipping: addressStringify(shippingAddress),
        Physical: addressStringify(physicalAddress),
      }}
      lineEditData={[
        homeAddress ?? emptyAddress,
        shippingAddress ?? { ...emptyAddress, type: "postal" },
        physicalAddress ?? { ...emptyAddress, type: "physical" },
      ]}
      initialValue={emptyAddress}
      validationSchema={getAddressSchema()}
      isUpdating={isUpdating}
      onSubmit={onSubmit}
      className="profile-card-section"
    >
      <AddressField context="Address" />
    </InformationCard>
  )
}

export { AddressInformation }
