import { ObservationValue, Organization, ServiceRequest, codeableConceptAsString } from "fhir"
import { getStringAddress } from "utils"

import {
  LabPanelDetailsComponent,
  LabPanelResult,
  LaboratoryOrderDetailsComponent,
  LaboratoryOrderPanel,
  PlaceOfService,
} from "./types"

const LAB_ORDER_DETAILS_STATUS_SYSTEM = "http://chartedhealth.com/fhir/order-status"

const getStatus = (labOrder: ServiceRequest) =>
  labOrder?.orderDetail?.find((item) => item?.coding?.[0]?.system === LAB_ORDER_DETAILS_STATUS_SYSTEM)?.coding?.[0]

const observationValueAsString = (value?: ObservationValue): string | boolean | undefined => {
  if (!value) return undefined
  const { Quantity, boolean, dateTime, integer, string, time, CodeableConcept } = value
  if (string) return string
  if (Quantity) {
    return Quantity.value?.toString()
  }
  if (integer) return integer.toString()
  if (boolean) return boolean
  if (CodeableConcept) return codeableConceptAsString(CodeableConcept)
  return time || dateTime
}

// funcion to get the Places of Service and the wrapped data of the panels
// we take advantage that we have to go through all the panels to get the places of service
// so we wrapped all the data in the same iteration and reduce overall cost for the app,
// because in one iteration we get all the data we need to render all the panels (panels + details) and places of service
// we created a LabPanelPOSDetails component to render the places of service
// we don`t use the same component for the panels because the data to render is different
const getPanelDetails = (panels: LaboratoryOrderPanel[], scrollToPosRef: (name: string) => void) => {
  if (panels.length === 0) return

  // we use a set to avoid duplicates
  const posNameSet = new Set()

  return panels.reduce<LaboratoryOrderDetailsComponent>(
    (acc: LaboratoryOrderDetailsComponent, panel: LaboratoryOrderPanel) => {
      const placesOfService: PlaceOfService[] = acc?.placesOfService ?? []
      const panels = acc?.panels ?? []
      const nonCancelledObservations = panel.observations?.filter(({ status }) => status !== "cancelled") ?? []

      // we iterate over the observations to get the places of service
      // and the wrapped data of the panels
      const data = {
        key: panel.profile.code,
        code: panel.profile.code,
        results: nonCancelledObservations.map((observation) => {
          const observationPOS = observation?.contained?.find(
            (resource) => observation.performer?.[0]?.localRef === (resource as Organization).id,
          ) as Organization | undefined
          const namePOS = observationPOS?.name
          // we add the place of service to the list if it is not already there
          if (namePOS && !posNameSet.has(namePOS)) {
            posNameSet.add(namePOS)
            placesOfService.push({
              key: observationPOS?.name,
              name: observationPOS?.name,
              address: getStringAddress(observationPOS?.address?.[0]),
              contact: observationPOS?.contact?.[0]?.name?.text,
              telecom: observationPOS?.telecom?.[0]?.value,
            })
          }

          return {
            key: observation.id ?? codeableConceptAsString(observation.code),
            scrollToPosRef: scrollToPosRef,
            code: codeableConceptAsString(observation.code),
            referenceRange: observation?.referenceRange?.[0].text,
            effectiveDateTime: observation?.effective?.dateTime,
            value: observationValueAsString(observation?.value),
            unit: observation?.value?.Quantity?.unit ?? observation.valueUnit ?? "",
            interpretation: observation?.interpretation?.[0].text,
            placeOfService: namePOS,
            notes: observation?.note,
            status: observation?.status,
            attachment: observation?.value?.Attachment,
          } as LabPanelResult
        }),
        totalResults: nonCancelledObservations.length,
      } as LabPanelDetailsComponent

      return { placesOfService, panels: [...panels, data] }
    },
    { placesOfService: [], panels: [] },
  )
}

export { getStatus, getPanelDetails }
