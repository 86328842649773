import { IconDefinition, faPhone } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "primereact/button"
import { Toast } from "primereact/toast"
import { useEffect, useRef, useState } from "react"

import { NotificationVCType } from "../data"
import { TNotificationSummaryVC, TNotificationVC } from "../types"
import { useBrowserNotification, useVideoCallContext, type TNotificationBuild } from "../hooks"

import chIconLogo from "images/chIconLogo.webp"

export const NotificationVideoCall = ({ type = "success" }: TNotificationVC) => {
  const [visible, setVisible] = useState(false)
  const [missing, setMissing] = useState(false)
  const { handleHangUpVC, handleJoinCall, recieveVC } = useVideoCallContext()
  const { handleNotification } = useBrowserNotification()
  const toastVC = useRef<Toast>(null)
  const showNotification = !!Object.keys(recieveVC).length

  const NotificationSummaryVC: TNotificationSummaryVC = {
    [NotificationVCType.success]: `${recieveVC?.practitionerFullName} is calling!`,
    [NotificationVCType.error]: `You have a missed call from ${recieveVC?.practitionerFullName}`,
  } as const

  const BrowserNotification: TNotificationBuild = {
    title: "Telemedicine",
    options: {
      body: NotificationSummaryVC[type],
      tag: "VideoCall",
      requireInteraction: true,
      icon: chIconLogo,
    },
  }

  const handleHangUpVCTimeOut = (seconds: number) => {
    const timeout = () => setTimeout(() => handleHangUpVC(), seconds * 1000)
    timeout()
    clearTimeout(timeout())
  }

  const clearVisibility = () => {
    toastVC.current?.clear()
    setVisible(false)
  }

  const clear = () => {
    clearVisibility()
    type !== NotificationVCType.error && handleHangUpVCTimeOut(0.5)
  }

  const join = () => {
    clearVisibility()
    handleJoinCall()
  }

  const resetNotificationType = () => {
    if (type === NotificationVCType.error) {
      setMissing(true)
    }
    clearVisibility()
  }

  useEffect(() => {
    if (missing) {
      confirm()
      setMissing(false)
    }
  }, [visible])

  useEffect(() => {
    showNotification && type === NotificationVCType.success && confirm()
  }, [recieveVC])

  useEffect(() => resetNotificationType(), [type])

  const confirm = () => {
    if (!visible) {
      toastVC.current?.clear()
      setVisible(true)
      handleNotification(BrowserNotification)
      toastVC.current?.show({
        severity: NotificationVCType[type],
        summary: NotificationSummaryVC[NotificationVCType[type]],
        sticky: true,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        content: (props) => (
          <div className="flex flex-col items-start w-full gap-4">
            <div className="font-medium text-lg my-3">{props.message.summary}</div>
            <div className="w-full flex justify-center gap-4">
              <IconCallBtn hidden={type === NotificationVCType.error} fontIcon={faPhone} onClick={join} />
              <IconCallBtn fontIcon={faPhone} mode="danger" onClick={() => clear()} />
            </div>
          </div>
        ),
      })
    }
  }

  return (
    <div className={`${!visible ? "hidden" : "flex"} justify-content-center z-10`}>
      <Toast ref={toastVC} position="top-center" onRemove={() => clear()} />
    </div>
  )
}

const IconCallBtn = ({ fontIcon, onClick, mode = "success", hidden }: TIconCallBtn) => (
  <Button
    onClick={onClick}
    className={`rounded-full ${hidden ? "hidden" : "flex"} justify-center items-center p-button-${mode} ${
      mode === "success" ? "animate-ring hover:animate-none" : "hover:scale-95 duration-200"
    } duration-100 opacity-85 hover:opacity-100 size-10`}
  >
    <FontAwesomeIcon className={`${mode === "danger" && "rotate-[135deg]"} text-xl`} color="#fff" icon={fontIcon} />
  </Button>
)

type TIconCallBtn = {
  hidden?: boolean
  fontIcon: IconDefinition
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  mode?: "danger" | "success"
}
