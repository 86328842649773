import { useMutation } from "@tanstack/react-query"

import { useClient } from "api"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { CustomError } from "commons"

const useShowPatientDocument = () => {
  const { getSignedUrl } = useClient()

  const getDocumentSignedUrl = async ({ url }: { url: string }) => {
    return getSignedUrl(url)
  }

  const { mutate: showDocument, isPending: isLoading } = useMutation({
    mutationFn: getDocumentSignedUrl,
    onSuccess: (signedUrl) => {
      const tab = window.open()
      if (tab) {
        tab.location.href = signedUrl.url
        tab.focus()
      }
    },
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
  })

  return { showDocument, isLoading }
}

export { useShowPatientDocument }
