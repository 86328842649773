import { useRef } from "react"
import { Menu } from "primereact/menu"
import { MenuItem } from "primereact/menuitem"
import { classNames } from "primereact/utils"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEllipsis, faSpinner } from "@fortawesome/pro-regular-svg-icons"

const CardListItem = ({
  rowImg,
  contentHeader,
  contentSubheader,
  className,
  contentClassName,
  actionsClassName,
  dropdownMenuItems,
  actionItem,
  onContentCliked,
  isLoading = false,
}: Props) => {
  const menu = useRef<Menu>(null)

  return (
    <div
      className={classNames(
        "flex items-center first:mt-1 last:mb-6 border-solid border-b border-slate-200/90 last:border-none",
        className,
      )}
    >
      {rowImg}

      <div
        className={classNames("item-content w-full flex items-center p-4", contentClassName)}
        onClick={onContentCliked}
      >
        <div className="flex-1 flex flex-col space-y-1 text-sm">
          <div className="inline-flex items-center">{contentHeader}</div>
          <div className="text-slate-400 inline-flex">{contentSubheader}</div>
        </div>
      </div>

      {isLoading ? (
        <FontAwesomeIcon icon={faSpinner} spin className="text-slate-400" size="lg" />
      ) : dropdownMenuItems?.length ? (
        <div
          className={classNames(
            "item-actions flex items-center justify-center content-center h-8 w-8 font-bold cursor-pointer text-slate-400 text-2xl rounded-full hover:bg-slate-100 overflow-hidden",
            actionsClassName,
          )}
        >
          <span
            className="inline-flex h-1/2 items-center justify-center"
            aria-controls="popup_menu"
            aria-haspopup
            onClick={(event) => menu.current && menu.current.toggle(event)}
          >
            <FontAwesomeIcon icon={faEllipsis} />
          </span>
          <Menu model={dropdownMenuItems} popup ref={menu} id="popup_menu" style={{ fontSize: "small" }} />
        </div>
      ) : (
        actionItem && (
          <span
            title={actionItem.label}
            className={classNames(
              "item-actions flex justify-center items-center h-8 w-8 cursor-pointer text-slate-400 font-semibold rounded-full hover:bg-slate-100",
              actionsClassName,
            )}
            onClick={(e) => !actionItem.disabled && actionItem.command?.({ originalEvent: e, item: actionItem })}
          >
            {actionItem.icon ?? actionItem.label}
          </span>
        )
      )}
    </div>
  )
}

type Props = {
  rowImg?: JSX.Element
  contentHeader?: JSX.Element
  contentSubheader?: JSX.Element
  className?: string
  contentClassName?: string
  actionsClassName?: string
  dropdownMenuItems?: MenuItem[]
  actionItem?: MenuItem
  onContentCliked?: () => void
  isLoading?: boolean
}

export { CardListItem }
