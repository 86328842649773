import { useEffect, useState } from "react"
import { Reference } from "fhir"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBuilding, faLocationDot } from "@fortawesome/pro-regular-svg-icons"
import { Dropdown } from "primereact/dropdown"
import { Button } from "primereact/button"
import { classNames } from "primereact/utils"

import { useAuth, useLoggedUserPatients } from "security"
import { usePatientOrgs } from "organization"
import { LoadingView } from "commons"
import { getStringAddress } from "utils"

const PatientSelection = ({ navToPatient }: Props) => {
  const { logout } = useAuth()
  const { patientRefs, patientsIndexed } = useLoggedUserPatients()
  const { isLoading, patientAndOrgs } = usePatientOrgs(patientsIndexed)
  const [selectedPat, setSelectedPatient] = useState<Reference>()
  const [error, setError] = useState(false)

  const isActiveOrg = (pat?: Reference) => patientAndOrgs?.[pat?.id ?? ""].active !== false

  useEffect(() => {
    setSelectedPatient(patientRefs.find((patientRef) => patientAndOrgs?.[patientRef.id ?? ""]?.active !== false))
  }, [patientAndOrgs])

  if (isLoading) return <LoadingView />

  const orgTemplate = (option: Reference) => {
    const org = patientAndOrgs?.[option.id!]
    const isAtive = org?.active !== false

    return (
      org && (
        <div className={classNames("text-sm", { "cursor-default": !isAtive })}>
          <div className={classNames("font-bold", { "opacity-50": !isAtive })}>
            <FontAwesomeIcon icon={faBuilding} className="fa-fw mr-1" />
            <span title="Organization" className="text-base">
              {org.name}
            </span>
          </div>
          <div title="Address" className={classNames({ "opacity-50": !isAtive })}>
            <FontAwesomeIcon icon={faLocationDot} className="fa-fw mr-1" />
            {getStringAddress(org.address?.[0])}
          </div>
          <small>
            {!isAtive && <span className="text-orange-700 italic">{`This organization is deactivated`}</span>}
          </small>
        </div>
      )
    )
  }

  return (
    <div className="flex flex-col items-center w-full">
      <div className="w-full text-gray-400 text-sm">Please select your practice</div>
      <div className="flex flex-col mt-4 w-full">
        <Dropdown
          className="w-full"
          name="currentPatient"
          options={patientRefs}
          optionLabel="display"
          itemTemplate={orgTemplate}
          resetFilterOnHide
          showFilterClear
          filter
          invalid={error}
          onInvalid={() => {}}
          filterBy="display"
          value={selectedPat}
          onChange={(e) => {
            if (e.value) {
              setSelectedPatient(e.value)
              setError(!isActiveOrg(e.value))
            }
          }}
        />
        <div className="flex items-start h-2 mt-2 pl-2">
          <small>
            {error && (
              <span className="p-error">
                <FontAwesomeIcon icon={faBuilding} />
                <span className="ml-1">This organization is deactivated</span>
              </span>
            )}
          </small>
        </div>
      </div>
      <Button
        className="w-full button-primary text-center mt-10"
        label="Accept"
        onClick={selectedPat ? () => navToPatient(selectedPat) : undefined}
        disabled={!selectedPat || error}
      />
      <Button className="w-full button-default text-center mt-3" label="Logout" onClick={logout} />
    </div>
  )
}

type Props = {
  navToPatient: (patient: Reference) => void
}

export { PatientSelection }
