import { FC, useMemo, useRef } from "react"
import { DiagnosticReport, Money, codeableConceptAsString } from "fhir"

import { getMoneyCurrencyAlt } from "utils"

import { LaboratoryOrderCombo, LaboratoryOrderPanel } from "../types"
import { LabPanelDetails } from "./LabPanelDetails"
import { LabPanelPOSDetails } from "./LabPanelPOSDetails"
import { getPanelDetails } from "../utils"
import { LaboratoryOrderPreviousResults } from "./LaboratoryOrderPreviousResults"

const LaboratoryOrderResults: FC<Props> = ({ panels, combo, previousResults, hidePrice, isOrgExemptLabPayment }) => {
  const posRef = useRef<HTMLSpanElement>()

  const scrollToPosRef = (name: string) => {
    posRef.current = window.document.getElementById(name) as HTMLSpanElement
    posRef.current.scrollIntoView(true)
  }

  const laboratoryPanelDetails = useMemo(() => getPanelDetails(panels ?? [], scrollToPosRef), [panels])
  const comboPanelDetails = useMemo(() => getPanelDetails(combo?.panels ?? [], scrollToPosRef), [combo?.panels])

  const hasCombo = !!combo
  const panelsPrice = useMemo(() => {
    return panels?.reduce((acc, panel) => {
      const price = panel.price?.value ?? 0
      return { currency: panel.price?.currency ?? acc.currency, value: (acc.value ?? 0) + price }
    }, {} as Money)
  }, [panels])

  return (
    <>
      {!!combo && (
        <div className="flex flex-col gap-4 border border-gray-200 rounded-lg p-2 ">
          <div className="flex flex-col">
            <div className="flex justify-between">
              <span className="text-primary font-semibold text-sm">
                {codeableConceptAsString(combo?.laboratoryCombo.code)}
              </span>
              {combo.price && !isOrgExemptLabPayment && !hidePrice && (
                <span className="text-gray-300 text-xs" title="Combo total">
                  {`${getMoneyCurrencyAlt(combo.price?.currency)}${combo.price?.value?.toFixed(2)}`}
                </span>
              )}
            </div>
            <span className="text-gray-300 text-xs">Combo</span>
          </div>
          <div className="flex flex-col">
            <span className="font-semibold text-sm text-gray-500">Tests</span>
            <div className="flex flex-col divide-y">
              {comboPanelDetails?.panels?.map((panel, index) => (
                <LabPanelDetails key={panel.key?.coding?.[0].code ?? index} panel={panel} />
              ))}
              <LabPanelPOSDetails placesOfService={comboPanelDetails?.placesOfService} />
            </div>
          </div>
        </div>
      )}
      {!!panels?.length && (
        <div className="flex flex-col gap-4 border border-gray-200 rounded-lg p-2 ">
          <div className="flex flex-col">
            <div className="flex justify-between">
              <span className="text-primary font-semibold text-sm">{hasCombo ? "Additional Testing" : "Tests"}</span>
              {!isOrgExemptLabPayment && !hidePrice && (
                <span className="text-gray-300 text-xs" title="Tests total">
                  {`${getMoneyCurrencyAlt(panelsPrice?.currency)}${(panelsPrice?.value ?? 0).toFixed(2)}`}
                </span>
              )}
            </div>
            {hasCombo && <span className="text-gray-300 text-xs">Tests that doesn't belong to combo</span>}
          </div>
          <div className="flex flex-col">
            {hasCombo && <span className="font-semibold text-sm text-gray-500">Tests</span>}
            <div className="flex flex-col divide-y">
              {laboratoryPanelDetails?.panels?.map((panel, index) => (
                <LabPanelDetails key={panel.key?.coding?.[0].code ?? index} panel={panel} />
              ))}
              <LabPanelPOSDetails placesOfService={laboratoryPanelDetails?.placesOfService} />
            </div>
          </div>
        </div>
      )}
      {!!previousResults?.length && <LaboratoryOrderPreviousResults previousResults={previousResults} />}
    </>
  )
}

type Props = {
  panels?: LaboratoryOrderPanel[]
  combo?: LaboratoryOrderCombo
  previousResults?: DiagnosticReport[]
  hidePrice?: boolean
  isOrgExemptLabPayment?: boolean
}

export { LaboratoryOrderResults }
