import { FC, useMemo, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faCopy } from "@fortawesome/pro-light-svg-icons"

import { CustomError } from "commons"

import { copyToClipboard, getCustomErrorMessage } from "../utils"

const NotificationError: FC<Props> = ({ error }) => {
  const [copiedToClipboard, setCopiedToClipboard] = useState(false)
  const errorMessage: string = useMemo(() => getCustomErrorMessage(error), [error])

  return (
    <div className="flex flex-col gap-1 md:max-w-72 2xl:max-w-60">
      <p className="p-error text-lg font-bold">Error</p>
      <p className="font-semibold">{errorMessage}</p>
      <p className="text-sm whitespace-normal break-words text-ellipsis line-clamp-2 overflow-hidden">
        {error.cause?.message}
      </p>
      {!!error.cause?.traceId && (
        <div className="text-sm">
          Feel free to contact support using de trace code below:
          <div className="flex justify-between items-baseline">
            <p className="px-1 select-all">{error.cause.traceId} </p>

            <FontAwesomeIcon
              icon={copiedToClipboard ? faCheck : faCopy}
              title={copiedToClipboard ? "Copied to clipboard" : "Copy to clipboard"}
              size="1x"
              className="text-base cursor-pointer hover:text-primary-hover"
              onClick={() =>
                !copiedToClipboard && copyToClipboard(error.cause?.traceId as string, () => setCopiedToClipboard(true))
              }
            />
          </div>
        </div>
      )}
    </div>
  )
}

type Props = { error: CustomError }

export { NotificationError }
