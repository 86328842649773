import { Form, Formik, FormikProps, FormikValues } from "formik"
import { Dialog } from "primereact/dialog"
import { classNames } from "primereact/utils"
import { ReactNode } from "react"
import { ObjectSchema } from "yup"

import { Button } from "../components/Buttons"

const DialogFormContainer = <T extends FormikValues>({
  showForm,
  title,
  initialValue,
  validationSchema,
  useFormik = initialValue !== undefined && validationSchema !== undefined,
  saveLabel = "Save",
  showCancelButton = true,
  className,
  onSubmit,
  onCancel,
  children,
}: Props<T>) => {
  const content = ({
    useSubmit,
    showCancelButton,
    props,
  }: {
    useSubmit: boolean
    showCancelButton?: boolean
    props?: FormikProps<T>
  }) => (
    <>
      <div className="flex flex-1 flex-col overflow-hidden">
        <div className={classNames("overflow-y-auto p-4 pt-0 sm:px-6 space-y-4", className)}>
          {typeof children === "function" ? children(props as FormikProps<T>) : children}
        </div>
      </div>
      <div className="flex flex-shrink-0 justify-end px-4 py-4 sm:px-6">
        {showCancelButton && (
          <Button
            label="Cancel"
            buttonStyle="default"
            size="xl"
            className="mr-3"
            // eslint-disable-next-line react/prop-types
            disabled={props?.isSubmitting}
            onClick={onCancel}
          />
        )}
        <Button
          label={saveLabel}
          type={useSubmit ? "submit" : "button"}
          size="xl"
          // eslint-disable-next-line react/prop-types
          loading={props?.isSubmitting}
          onClick={!useSubmit ? () => onSubmit() : undefined}
        />
      </div>
    </>
  )

  return (
    <Dialog
      modal
      header={title}
      visible={showForm}
      draggable={false}
      resizable={false}
      appendTo={useFormik && initialValue ? undefined : "self"}
      onHide={onCancel}
      className="dialog-form-container w-full sm:w-4/5 md:w-2/3 lg:w-[36rem]"
    >
      {useFormik && initialValue ? (
        <Formik initialValues={initialValue} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize>
          {(props) => (
            <Form
              className="bg-white divide-gray-200 divide-y flex flex-col flex-1 overflow-hidden"
              aria-autocomplete="none"
              autoComplete="off"
            >
              {content({ useSubmit: true, showCancelButton, props })}
            </Form>
          )}
        </Formik>
      ) : (
        <div className="bg-white divide-gray-200 divide-y flex flex-col flex-1 overflow-hidden">
          {content({ useSubmit: false, showCancelButton })}
        </div>
      )}
    </Dialog>
  )
}

type Props<T> = {
  className?: string
  showForm: boolean
  title?: string
  useFormik?: boolean
  initialValue?: T
  saveLabel?: string
  onSubmit(data?: T): void
  showCancelButton?: boolean
  onCancel(): void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validationSchema?: ObjectSchema<any>
  children: ((props: FormikProps<T>) => ReactNode) | ReactNode
}

export { DialogFormContainer }
