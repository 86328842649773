import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExternalLink } from "@fortawesome/pro-light-svg-icons"
import { FC, useEffect } from "react"
import { v4 } from "uuid"

import { datadogLogs } from "logger"
import { CustomError } from "commons"

import { ERROR_CAUSE } from "../types"
import { getCustomErrorMessage } from "../utils"

const InternalServerErrorView: FC<Props> = ({ error }) => {
  const message = error.cause?.message ?? ERROR_CAUSE.INTERNAL_SERVER_ERROR
  const code = error.cause?.name ?? "500"
  const apmTransactionTraceId = v4()

  useEffect(() => {
    const customErr: CustomError = {
      ...error,
      cause: {
        ...error.cause,
        traceId: apmTransactionTraceId,
      },
    }

    datadogLogs.logger.error(error.message, { custom_trace: apmTransactionTraceId, data: customErr }, error)
  }, [])

  return (
    <div className="flex items-center justify-center w-full h-full">
      <div className="text-center">
        <p className="p-error text-5xl">{code}</p>
        <h1 className="text-4xl py-2 font-bold">{getCustomErrorMessage(error)}</h1>
        <p className="text-gray-400 pt-2 pb-4">{message}</p>
        <div className="mt-3 mb-5">
          Feel free to contact support using de trace code below:
          <p className="px-1 select-all"> {apmTransactionTraceId}</p>
        </div>
        <a href="/" className="p-button button-primary">
          <span>
            Go to Home <FontAwesomeIcon icon={faExternalLink} className="pl-1" />
          </span>
        </a>
      </div>
    </div>
  )
}

type Props = { error: CustomError }

export { InternalServerErrorView }
