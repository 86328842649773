export const KeysNames = {
  sessionId: "sessionId",
  accessToken: "accessToken",
  applicationId: "applicationId",
} as const

export const IdToAppendVideo = {
  publisher: "publisher",
  subscriber: "subscriber",
  shareScreen: "shareScreen",
} as const

export const VonageSignalPractitioner = {
  audioMute: "audioMutePractitioner",
} as const

export const VonageSignalPatient = {
  audioMute: "audioMutePatient",
} as const

export const NotificationVCType = {
  success: "success",
  error: "error",
} as const

export const SettingsBtnsNames = {
  endCall: "End call",
  audio: "Audio",
  video: "Video",
  pip: "Picture in picture",
  shareScreen: "Share screen",
} as const
