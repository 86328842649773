import { Coverage } from "fhir"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useClient } from "api"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"
import { CustomError } from "commons"

import { coverageKeys } from "../query-keys"

const useCreateCoverage = (hideForm: () => void) => {
  const { create, update } = useClient()
  const queryClient = useQueryClient()

  const createFn = async (coverage: Coverage) =>
    coverage.id ? update("Coverage", coverage.id, coverage) : create("Coverage", coverage)

  const { mutate: createCoverage, isPending: isAdding } = useMutation({
    mutationFn: createFn,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async (_, coverage) => {
      await queryClient.invalidateQueries({ queryKey: coverageKeys.all })

      displayNotificationSuccess(coverage.id ? "Insurance updated successfully!" : "Insurance created successfully!")
    },
    onSettled: hideForm,
  })

  return { createCoverage, isAdding }
}

export { useCreateCoverage }
