import { faCalendar, faCalendarDay, faEye, faTrashCan } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { StackedListItemProps } from "commons"
import { MenuStyles } from "commons/types"
import { formatsByTypes } from "data"
import { formatDate, getBadgeColor } from "utils"

import { PlanData } from "../types"

const planItemModel = ({
  plan,
  isLoading,
  viewPlan,
  cancelPlan,
  contextLabel,
}: PlanItemModelArgs): StackedListItemProps => ({
  isLoading,
  leftData: [
    {
      lineItems: [
        { name: "Title", value: plan.carePlan.title ?? plan.planDefinition?.title ?? `Unnamed ${contextLabel}` },
      ],
    },
    {
      lineItems: [
        {
          name: "Created",
          value: plan.carePlan.created
            ? formatDate(new Date(plan.carePlan.created), formatsByTypes.LONG_DATE)
            : "No date",
          icon: faCalendar,
        },
        //...(plan.carePlan?.author ? [{ name: "Author", value: plan.carePlan?.author.display, icon: faUser }] : []),
        ...(plan.appointment?.start
          ? [
              {
                name: "Appointment",
                icon: faCalendarDay,
                value: formatDate(new Date(plan.appointment.start), formatsByTypes.LONG_DATE),
              },
            ]
          : []),
      ],
    },
  ],
  badge: getBadgeColor(
    plan.carePlan.status === "revoked"
      ? "cancelled"
      : contextLabel === "order" && plan.carePlan.status === "active"
        ? "pending"
        : plan.carePlan.status,
  ),
  menu: [
    ...(plan.carePlan.status !== "draft"
      ? [
          {
            label: `View ${contextLabel} details`,
            icon: <FontAwesomeIcon icon={faEye} />,
            command: viewPlan,
            disabled: plan.carePlan.status === "draft",
          },
        ]
      : []),
    ...(cancelPlan
      ? [
          {
            label: "Cancel",
            icon: <FontAwesomeIcon icon={faTrashCan} />,
            command: cancelPlan,
            disabled: !["active", "draft"].includes(plan.carePlan.status),
          },
        ]
      : []),
  ],
  menuStyle: !cancelPlan ? MenuStyles.ExternalAction : undefined,
})

interface PlanItemModelArgs {
  plan: PlanData
  isLoading: boolean
  viewPlan: () => void
  cancelPlan?: () => void
  contextLabel?: string
}

export { planItemModel }
