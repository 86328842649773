import { useQuery } from "@tanstack/react-query"
import { compareDesc } from "date-fns"
import {
  DiagnosticReport,
  DocumentReference,
  getResource,
  getResources,
  getResourcesByTypeAsIndex,
  humanNameAsString,
  Invoice,
  Observation,
  PlanDefinition,
  Practitioner,
  QuestionnaireResponse,
  ServiceRequest,
  Specimen,
  Task,
} from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { useCIDQueryFunction } from "commons"
import { BillingTypeCodes, ServiceRequestCategory } from "data"
import {
  convertIdentifiersToCodings,
  getBasePrice,
  getCidIdentifier,
  getCommonCode,
  getServiceRequestBillingType,
  mergeSort,
} from "utils"

import { laboratoryOrderKeys } from "../query-keys"
import { LaboratoryOrder, LaboratoryOrderPanel } from "../types"

const useLaboratoryOrder = (organizationId: string, laboratoryOrderId: string, patientId: string) => {
  const { search, getSignedUrl } = useClient()
  const queryKey = laboratoryOrderKeys.withOrderId(laboratoryOrderId)

  const getChargeItemDefinitions = useCIDQueryFunction()

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        _query: "lab-order-details",
        _id: laboratoryOrderId,
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/ServiceRequest`, filters, signal })
      const serviceRequests = getResources<ServiceRequest>(bundle, "ServiceRequest")

      const order = serviceRequests.filter(
        (sr) => sr.category?.[0].coding?.[0].code === ServiceRequestCategory.LAB_ORDER,
      )[0]

      if (!order) {
        throw new Error("Not found", { cause: { name: "404", message: "Not laboratory order found" } })
      }

      const planDefinitions = getResources<PlanDefinition>(bundle, "PlanDefinition")
      const practitioner = getResource<Practitioner>(bundle, "Practitioner")
      const tasks = getResources<Task>(bundle, "Task")
      const invoices = getResources<Invoice>(bundle, "Invoice")
      const specimens = getResources<Specimen>(bundle, "Specimen")
      const questionnaireResponses = getResources<QuestionnaireResponse>(bundle, "QuestionnaireResponse")
      const codes = convertIdentifiersToCodings(planDefinitions)
      const document = getResource<DocumentReference>(bundle, "DocumentReference")

      const billingType = getServiceRequestBillingType(order)

      const chargeItemDefinitions = await getChargeItemDefinitions(
        organizationId,
        billingType === BillingTypeCodes.BILL_PATIENT
          ? { billToPatientCIDs: codes }
          : { billToPracticeOrInsuranceCIDs: codes },
      )

      const diagnosticReports = getResources<DiagnosticReport>(bundle, "DiagnosticReport")
      const sortedDR = mergeSort(
        diagnosticReports?.filter((dr) => dr.presentedForm?.[0]?.url && dr.issued) ?? [],
        "issued",
        (a, b) => compareDesc(new Date(a), new Date(b)),
      )
      const newestDR = sortedDR[0]

      const indexedObservations = getResourcesByTypeAsIndex<Observation>(bundle, "Observation")
      const sortedObservations = newestDR?.result?.map(({ id }) => indexedObservations[id ?? ""]) ?? []
      const groupedObservations = sortedObservations.reduce<Record<string, Observation[]>>((prev, observation) => {
        const panelRef = observation.basedOn?.find(({ resourceType, id }) => resourceType === "ServiceRequest" && id)

        if (panelRef?.id) {
          return { ...prev, [panelRef.id]: [...(prev[panelRef.id] ?? []), observation] }
        }

        return prev
      }, {})

      let requisition = ""
      if (document?.content?.[0]?.attachment?.url) {
        const signedUrl = await getSignedUrl(document?.content?.[0]?.attachment?.url)
        requisition = signedUrl.url
      }

      return {
        order,
        serviceRequests,
        planDefinitions,
        diagnosticReports: sortedDR,
        observations: Object.values(indexedObservations),
        chargeItemDefinitions,
        tasks,
        invoices,
        specimens,
        questionnaireResponses,
        groupedObservations,
        requisition,
        sortedObservations,
        requester: humanNameAsString(practitioner?.name?.[0]),
        indexedObservations,
        diagnosticReport: newestDR,
      }
    },
    meta: { context: { queryKey, laboratoryOrderId } },
  })

  const laboratoryOrder = useMemo(() => {
    const defaultLaboratoryPanels = {
      laboratoryPanels: new Array<LaboratoryOrderPanel>(),
      totalPrice: { currency: "USD", value: 0 },
    }

    const indexedQrs = data?.questionnaireResponses.reduce<Record<string, QuestionnaireResponse>>((acc, qr) => {
      return { ...acc, [qr.id as string]: qr }
    }, {})

    const planBased = data?.order?.basedOn?.some((r) => r.resourceType === "CarePlan") ?? false
    const revocableTaskStatus = ["draft", "on-hold", "failed"]
    const orderLabsTask = data?.tasks.find((task) => task.code?.coding?.[0].code === "order-labs")
    const revocable = !planBased && (!orderLabsTask || revocableTaskStatus.includes(orderLabsTask.status))

    const getPanelData = (sr: ServiceRequest, billingType: BillingTypeCodes) => {
      const panelInstantiateCanonical = sr.instantiatesCanonical?.[0] ?? ""
      const [pdUrl, pdVersion] = panelInstantiateCanonical.split("|")
      const planDefinition = data?.planDefinitions.find((pd) => pd.url === pdUrl && pd.version === pdVersion)
      const codes = convertIdentifiersToCodings(planDefinition ? [planDefinition] : [])

      const cid =
        billingType === BillingTypeCodes.BILL_PATIENT
          ? data?.chargeItemDefinitions.billToPatientCIDs?.[getCidIdentifier(getCommonCode(codes))]
          : data?.chargeItemDefinitions.billToPracticeOrInsuranceCIDs?.[getCidIdentifier(getCommonCode(codes))]
      const cidPrice = getBasePrice(cid?.propertyGroup?.[0].priceComponent)?.amount

      const panelQrs = sr.supportingInfo?.flatMap((qr) =>
        indexedQrs?.[qr.id as string] ? indexedQrs?.[qr.id as string] : [],
      )

      let observations: Observation[] = []

      if (sr.id) {
        observations = data?.groupedObservations?.[sr.id] ?? []
      }

      const labPanel: LaboratoryOrderPanel = {
        profile: sr,
        price: cidPrice,
        observations,
        questionnairesResponses: panelQrs,
      }

      return {
        labPanel,
        panelPrice: cidPrice,
      }
    }

    const indexedServiceRequests = data?.serviceRequests.reduce<Record<string, ServiceRequest>>((acc, sr) => {
      return { ...acc, [sr.id as string]: sr }
    }, {})

    const comboSr = data?.serviceRequests.find(
      (sr) => sr.category?.[0].coding?.[0].code === ServiceRequestCategory.LAB_ORDER_COMBO,
    )
    const orderBillingType = getServiceRequestBillingType(data?.order)
    const comboData = comboSr ? getPanelData(comboSr, orderBillingType) : undefined

    const uniqueSRs = new Set<string>()
    const totalPrice = { currency: "USD", value: 0 }

    const comboPanels = comboSr?.basedOn?.reduce((acc, { id }) => {
      const sr = indexedServiceRequests?.[id as string]
      if (sr?.id) uniqueSRs.add(sr.id)

      return sr ? [...acc, getPanelData(sr, orderBillingType).labPanel] : acc
    }, new Array<LaboratoryOrderPanel>())

    const orderCombo =
      comboData && comboSr && comboPanels
        ? { laboratoryCombo: comboSr, panels: comboPanels ?? [], price: comboData.panelPrice }
        : undefined

    if (orderCombo) {
      totalPrice.value += orderCombo.price?.value ?? 0
    }

    const { laboratoryPanels: laboratoryPanelsWithResults, totalPrice: totalPriceWithResults } =
      data?.diagnosticReport?.result?.reduce((prev, { id }) => {
        const observation = data?.indexedObservations[id as string]
        const srId = observation.basedOn?.[0]?.id as string
        const alreadyAdded = uniqueSRs.has(srId)

        if (alreadyAdded) return prev
        uniqueSRs.add(srId)

        const sr = indexedServiceRequests?.[srId]

        if (sr && sr.id && sr.category?.[0].coding?.[0].code === ServiceRequestCategory.LAB_ORDER_PANEL) {
          const panelData = getPanelData(sr, orderBillingType)

          return {
            laboratoryPanels: [...prev.laboratoryPanels, panelData.labPanel],
            totalPrice: { currency: "USD", value: prev.totalPrice.value + (panelData.panelPrice?.value ?? 0) },
          }
        }

        return prev
      }, defaultLaboratoryPanels) ?? defaultLaboratoryPanels

    const { laboratoryPanels: laboratoryPanelsWithoutResuls, totalPrice: totalPriceWithoutResults } =
      Object.entries(indexedServiceRequests ?? {}).reduce((prev, [srId, sr]) => {
        if (uniqueSRs.has(srId)) {
          return prev
        }

        if (sr.id && sr.category?.[0].coding?.[0].code === ServiceRequestCategory.LAB_ORDER_PANEL) {
          const panelData = getPanelData(sr, orderBillingType)

          return {
            laboratoryPanels: [...prev.laboratoryPanels, panelData.labPanel],
            totalPrice: { currency: "USD", value: prev.totalPrice.value + (panelData.panelPrice?.value ?? 0) },
          }
        }

        return prev
      }, defaultLaboratoryPanels) ?? defaultLaboratoryPanels

    const laboratoryPanels = [...laboratoryPanelsWithResults, ...laboratoryPanelsWithoutResuls]

    /* Add extra panels price to total price */
    totalPrice.value += totalPriceWithResults.value + totalPriceWithoutResults.value

    const laboratoryOrder: LaboratoryOrder = {
      order: data?.order as ServiceRequest,
      panels: laboratoryPanels,
      billingType: orderBillingType,
      panelsCount: laboratoryPanels.length ?? 0,
      price: totalPrice,
      requester: data?.requester as string,
      observations: data?.observations ?? [],
      presentedForm: data?.diagnosticReports?.[0]?.presentedForm?.[0]?.url ?? "",
      bloodDrawnInOffice: !!data?.order.specimen,
      specimenDate: data?.specimens?.[0]?.receivedTime,
      revocable,
      combo: orderCombo,
      previousResults: data?.diagnosticReports.filter(({ released }) => !!released),
      latestResult: data?.diagnosticReport,
    }

    return laboratoryOrder
  }, [data])

  return {
    laboratoryOrder,
    diagnosticReport: data?.diagnosticReports?.[0],
    tasks: data?.tasks ?? [],
    invoice: data?.invoices?.[0],
    requisition: data?.requisition,
    isLoading,
    observations: data?.sortedObservations,
  }
}

export { useLaboratoryOrder }
