import { InvoiceDetails, InvoiceItemGroupName, InvoiceProvider } from "@chartedhealth/payment-components"
import { FC, Suspense, useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { v4 } from "uuid"

import { LoadingView } from "commons"
import { useLoginContext } from "security"
import { useSignalR } from "signalR"

const InvoiceDetailsContainer: FC = () => {
  const { loggedInPatient, loggedInPatientId } = useLoginContext()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const invoiceId = searchParams.get("invoiceId")

  const showPaymentDetails = (paymentId: string) => {
    searchParams.delete("invoiceId")
    searchParams.set("paymentId", paymentId)

    setSearchParams(searchParams)
  }

  const { connection, joinGroup, leaveGroup } = useSignalR({ url: window.VITE_APP_SIGNALR_PAYMENT_NOTIFICATION })

  if (invoiceId && connection) {
    joinGroup(`Invoice/${invoiceId}`)
    connection.on("InvoiceUpdated", () => (providerKey = v4()))
  }

  let providerKey = v4()

  useEffect(() => {
    return () => {
      leaveGroup(`Invoice/${invoiceId}`)
    }
  }, [])

  if (!invoiceId) {
    return null
  }

  const showOrderDetails = (orderId: string, productType: InvoiceItemGroupName) => {
    switch (productType) {
      case "laboratory":
        navigate(`/patient/${loggedInPatientId}/labs?order=${orderId}`)
        break

      case "nutraceutical":
        navigate(`/patient/${loggedInPatientId}/nutraceuticals?view=orders&order=${orderId}`)
        break

      default:
        return
    }
  }

  return (
    <div className="relative h-full w-full flex flex-col overflow-auto bg-white">
      <Suspense fallback={<LoadingView />}>
        <InvoiceProvider
          key={`${invoiceId}-${providerKey}`}
          invoiceId={invoiceId}
          patient={loggedInPatient}
          isLabModuleEnabled
          showPaymentDetails={showPaymentDetails}
          showOrderDetails={showOrderDetails}
        >
          <InvoiceDetails />
        </InvoiceProvider>
      </Suspense>
    </div>
  )
}

export { InvoiceDetailsContainer }
