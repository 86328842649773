import { createElement } from "react"
import { toast } from "react-toastify"

import { CustomError } from "commons"

import { NotificationError } from "./components"

const displayNotificationError = (error: CustomError) => {
  if (window.innerWidth > 768) {
    toast.error(createElement(NotificationError, { error }), { autoClose: 2000, closeOnClick: false })
  } else {
    toast.error(createElement(NotificationError, { error }), {
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: false,
      style: { bottom: "4rem", minHeight: 0 },
    })
  }
}

const getCustomErrorMessage = (error: CustomError) => {
  const cause = error.cause?.name
  const message = error.message

  if (["400", "405", "406", "413", "414", "422", "429", "500"].includes(cause as string)) {
    return "We're sorry. Your operation couldn't be completed due an internal error. We're looking into the causes"
  } else if (["401", "403", "407", "423"].includes(cause as string)) {
    return "We're sorry. It seems we couldn't grant you access for this request. Try to log in again"
  } else if (["404", "410"].includes(cause as string)) {
    return "We're sorry, but the target resource couldn't be found. We're looking into the causes"
  } else if (["402"].includes(cause as string)) {
    return "We sorry. It appears that your payment could not be processed. Please verify your payment information and try again"
  } else if (message) {
    return message
  } else return "We're sorry, but due an error we couldn't satisfy your request. We're looking into the causes"
}

const copyToClipboard = async (trace: string, onSuccess?: () => void) => {
  navigator.clipboard
    .writeText(trace)
    .then(onSuccess)
    .catch(() => {
      console.error("Failed to copy. Check navigator permits.")
      /* Rejected - text failed to copy to the clipboard */
    })
}

export { displayNotificationError, getCustomErrorMessage, copyToClipboard }
