import { Invoice } from "fhir"
import { Chip } from "primereact/chip"
import { classNames } from "primereact/utils"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendarDays } from "@fortawesome/pro-solid-svg-icons"
import { faScannerGun } from "@fortawesome/pro-regular-svg-icons"
import { parseISO } from "date-fns"

import { formatDate, getMoneyCurrencyAlt } from "utils"
import { CardListItem } from "commons"
import { formatsByTypes, invoiceStatusCodes, systems } from "data"

const InvoiceListItem = ({ invoice, className, onClick }: Props) => {
  // const dropdownItems = [
  //   {
  //     label: "Refresh",
  //     icon: <FontAwesomeIcon icon={faRotate} size="sm" className="mr-2" />,
  //     command: () => {},
  //   },
  //   {
  //     label: "Cancel",
  //     icon: <FontAwesomeIcon icon={faTrashCan} size="sm" className="mr-2" />,
  //     command: () => {},
  //   },
  // ]

  const head = (
    <>
      <div className="font-bold" title="Invoice number">
        {`#${invoice.identifier?.find(({ system }) => system === systems.invoice)?.value ?? "Unspecified number"}`}
      </div>
      <span title="Status">
        <Chip
          label={invoiceStatusCodes.find((status) => status.code === invoice.status)?.display}
          className={classNames("ml-2 custom-chip", {
            "is-danger": invoice.status === "cancelled",
            "is-warning": invoice.status === "entered-in-error",
            "is-success": invoice.status === "balanced",
            "is-info": invoice.status === "issued",
            "is-secondary": invoice.status === "draft",
          })}
        />
      </span>
    </>
  )

  const details = (
    <div className="inline-flex items-center">
      {invoice.date && (
        <>
          <span title="Issued date">
            <FontAwesomeIcon icon={faCalendarDays} size="xs" className="mr-2" />
            {formatDate(parseISO(invoice.date), formatsByTypes.LONG_DATE)}
          </span>
          <span className="mx-1">|</span>
        </>
      )}
      <span title="Total Net">
        {invoice?.totalGross?.value
          ? getMoneyCurrencyAlt(invoice?.totalGross.currency) + invoice.totalGross.value.toFixed(2)
          : "$0.00"}
      </span>
      <span className="mx-1">|</span>
      <span title="Items">
        <FontAwesomeIcon icon={faScannerGun} size="xs" className="mr-1" />
        {invoice.lineItem?.filter((item) => item.priceComponent?.[0]?.type === "base").length}
      </span>
    </div>
  )

  return (
    <CardListItem
      contentHeader={head}
      contentSubheader={details}
      // dropdownMenuItems={dropdownItems}
      className={classNames("cursor-pointer", className)}
      onContentCliked={onClick}
    />
  )
}

type Props = {
  invoice: Invoice
  className?: string
  onClick?(): void
}

export { InvoiceListItem }
