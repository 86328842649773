import { codeableConceptAsString, Duration, MedicationKnowledge } from "fhir"
import { dispenseInterval } from "./data"

const getAutoCompleteMedFieldText = (e?: MedicationKnowledge) => {
  const textCode = codeableConceptAsString(e?.code)
  const packagingText = e?.packaging
    ? `${e?.packaging?.quantity?.value ?? "unspecified"} ${e?.packaging?.quantity?.unit ?? "unspecified"}`
    : ""

  const value = `${textCode} - ${packagingText}`
  return value
}

const getDispenseFrequency = (interval: Duration | undefined) => {
  const dispense =
    interval &&
    dispenseInterval.find(
      ({ value: { code, system, unit, value } }) =>
        interval?.system === system && interval?.code === code && interval?.value === value && interval?.unit === unit,
    )

  return dispense
}

export { getAutoCompleteMedFieldText, getDispenseFrequency }
