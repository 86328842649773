import { faEnvelope, faMobile } from "@fortawesome/pro-regular-svg-icons"
import { faSearch } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { asReference, codeableConceptAsString, getFirstPhone, humanNameAsString, Reference, RelatedPerson } from "fhir"
import { useEffect } from "react"

import {
  AddFieldArrayItemButton,
  ConfirmDialog,
  DialogFormContainer,
  SkeletonLoader,
  StackedListContainer,
  StackedListItemProps,
  useCrudReducer,
} from "commons"
import { useLoginContext } from "security"
import { strCapitalize } from "utils"
import { defaultEditRemoveMenu } from "utils-renders"

import { useCreateRelatedPerson, useDeleteRelatedPerson, useRelatedPerson, useRPLinkedCoverages } from "../hooks"
import { RelatedPersonForm } from "./RelatedPersonForm"
import { getRelatedPersonInitialValues, relatedPersonValidationSchema } from "./validations"

const RelatedPersonContainer = ({ autoSelect, selectedCode, selectedPerson, setSelectedPerson }: Props) => {
  const { loggedInPatientId } = useLoginContext()
  const { relatedPeople, personRefbyCode, prIds, isLoading } = useRelatedPerson(loggedInPatientId)
  const { rpLinkedCoverages, isLoadingRPLink } = useRPLinkedCoverages(loggedInPatientId, prIds)

  const { showSlide, initialValue, deleteIndex, add, edit, reset, setDeleteIndex } = useCrudReducer({
    defaultEntity: getRelatedPersonInitialValues(loggedInPatientId),
  })

  const { createRelatedPerson, isAdding } = useCreateRelatedPerson(reset)
  const { deleteRelatedPerson, isDeleting } = useDeleteRelatedPerson(reset)

  useEffect(() => {
    if (!isLoading && autoSelect) setSelectedPerson(personRefbyCode[selectedCode] ?? {})
  }, [selectedCode, isLoading])

  const onSubmit = (data: RelatedPerson) => createRelatedPerson(data)

  if (isLoading || isLoadingRPLink) return <SkeletonLoader loaderType="two-lines" repeats={2} />

  return (
    <>
      {relatedPeople.length > 0 ? (
        <StackedListContainer
          itemsClassName="px-2 py-4"
          data={relatedPeople}
          itemModelBuilder={(item) =>
            relatedPersonModel(
              item,
              item.id === selectedPerson,
              () => setSelectedPerson(asReference(item)),
              () => edit(item),
              !rpLinkedCoverages.includes(item.id as string) ? () => setDeleteIndex(item.id) : undefined,
            )
          }
        />
      ) : (
        <div className="flex flex-col items-center justify-center py-5">
          <FontAwesomeIcon icon={faSearch} size="lg" className="text-slate-500" />
          <p className="text-slate-500 text-xs pt-1">No related person added yet</p>
        </div>
      )}
      <AddFieldArrayItemButton className="px-2 py-4" onClick={add} />
      <DialogFormContainer
        title="Subscriber"
        showForm={showSlide || isAdding}
        useFormik
        initialValue={initialValue}
        validationSchema={relatedPersonValidationSchema}
        onCancel={reset}
        onSubmit={onSubmit}
      >
        <RelatedPersonForm />
      </DialogFormContainer>
      <ConfirmDialog
        confirmText="Are you sure you want to remove this related person?"
        actionName="Remove"
        visible={deleteIndex !== undefined || isDeleting}
        isLoading={isDeleting}
        onConfirm={() => deleteRelatedPerson(deleteIndex as string)}
        hideDialog={() => setDeleteIndex(undefined)}
      />
    </>
  )
}

const relatedPersonModel = (
  relatedPerson: RelatedPerson,
  isSelected: boolean,
  onClick: () => void,
  onEdit: () => void,
  onDelete?: () => void,
): StackedListItemProps => ({
  onClick,
  itemClassName: isSelected ? "ring-2 ring-slate-500 ring-inset rounded-lg" : "",
  leftData: [
    {
      lineItems: [
        { name: "Name", value: humanNameAsString(relatedPerson?.name?.[0]) },
        ...(relatedPerson
          ? [
              {
                name: "Relationship",
                value: `(${strCapitalize(codeableConceptAsString(relatedPerson?.relationship?.[0]))})`,
              },
            ]
          : []),
      ],
    },
    {
      lineItems: [
        ...(relatedPerson?.telecom?.[0]
          ? [
              {
                name: strCapitalize(relatedPerson?.telecom[0].system as string),
                value: relatedPerson?.telecom[0].value,
                icon: faEnvelope,
              },
            ]
          : []),
        ...(relatedPerson?.telecom?.[1]
          ? [
              {
                name: strCapitalize(relatedPerson?.telecom[1].system as string),
                value: getFirstPhone(relatedPerson?.telecom),
                icon: faMobile,
              },
            ]
          : []),
      ],
    },
  ],
  menu: defaultEditRemoveMenu({ onEdit, onDelete }),
})

type Props = {
  autoSelect: boolean
  selectedCode: string
  selectedPerson?: string
  setSelectedPerson(personRef: Reference): void
}

export { RelatedPersonContainer }
