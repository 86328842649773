import { FieldProps } from "formik"
import { classNames } from "primereact/utils"
import { FC } from "react"
import { Dropdown } from "primereact/dropdown"
import { Coding, Composition, Reference } from "fhir"

import { FormField, FormFieldBaseProps } from "./FormField"

const DropdownField: FC<Props> = ({
  options,
  inputClassName,
  disabled,
  optionLabel = "label",
  optionValue = "code",
  filterBy,
  ...fieldProps
}) => (
  <FormField {...fieldProps}>
    {({ field: { name, value, onChange }, meta: { touched, error } }: FieldProps) => (
      <Dropdown
        id={name}
        name={name}
        optionLabel={optionLabel}
        optionValue={optionValue}
        options={options}
        onChange={onChange}
        value={value}
        disabled={disabled}
        filter={filterBy !== undefined}
        filterBy={filterBy}
        className={classNames(
          { "p-invalid": touched && error, horizontal: fieldProps.horizontal },
          "h-10 p-inputtext-sm",
          inputClassName,
        )}
        resetFilterOnHide
      />
    )}
  </FormField>
)

type Props = FormFieldBaseProps<string | number | Composition | Coding> & {
  options: Record<string, string | number>[] | Composition[] | Coding[] | Reference[]
  disabled?: boolean
  optionLabel?: string
  optionValue?: string
  inputClassName?: string
  filterBy?: string
}

export { DropdownField }
