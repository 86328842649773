import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useClient } from "api"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"
import { CustomError } from "commons"

import { relatedPersonKeys } from "../query-keys"

const useDeleteRelatedPerson = (onSettled: () => void) => {
  const { remove } = useClient()
  const queryClient = useQueryClient()

  const removeFn = async (relatedPersonId: string) => remove("RelatedPerson", relatedPersonId)

  const { mutate: deleteRelatedPerson, isPending: isDeleting } = useMutation({
    mutationFn: removeFn,
    onError: (error: CustomError, id) => {
      displayNotificationError(registerErrorTrace(error, { relatedPersonId: id }))
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: relatedPersonKeys.all })

      displayNotificationSuccess("Related person deleted successfully!")
    },
    onSettled,
  })

  return { deleteRelatedPerson, isDeleting }
}

export { useDeleteRelatedPerson }
