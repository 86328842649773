import { useSearchParams } from "react-router-dom"
import { useId, useReducer, startTransition, useState } from "react"
import InfiniteScroll from "react-infinite-scroller"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/pro-regular-svg-icons"
import { SelectButton } from "primereact/selectbutton"
import { ConfirmDialog } from "primereact/confirmdialog"

import { SkeletonLoader, SearchWithStatus } from "commons"
import { medicationRequestOrdersStatus } from "data"
import { useLoginContext } from "security"

import { Contents, OrderInfo } from "../../types"
import { useCancelMrOrder, useMrOrders } from "../../hooks"
import { MedicationOrderListItem } from "./MedicationOrderListItem"
import { CancelOrderConfirmation } from "./CancelOrderConfirmation"

const MedicationOrdersList = ({ activeContent, contentOptions, onUpdateContent }: Props) => {
  const { loggedInPatientId } = useLoginContext()
  const [params, setParams] = useSearchParams()
  const [orderToCancel, setOrderToCancel] = useState<OrderInfo>()

  const loaderKey = useId()
  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />

  const goToOrderDetails = (orderId: string) => {
    params.set("order", orderId)
    setParams(params)
  }

  const { statusFilter, itemActionClicked, searchFilter, onActionCliked, updateFilter, updateSearchFilter } =
    useReducerState()
  const { orders, isLoading, hasNextPage, fetchNextPage } = useMrOrders(loggedInPatientId, statusFilter, searchFilter)

  const { cancelMrOrder, isCancelling } = useCancelMrOrder(() => setOrderToCancel(undefined))
  const cancelOrder = (orderId: string, reason: string, stopOrder: boolean) => {
    onActionCliked(orderId)
    cancelMrOrder({
      patientId: loggedInPatientId,
      serviceRequestId: orderId,
      cancelReason: reason,
      stopSequence: stopOrder,
    })
  }

  return (
    <div className="module-container flex flex-col h-full relative overflow-auto p-3 lg:px-5 pb-0">
      <div className="flex flex-wrap justify-end md:inline-flex md:justify-between md:h-12 w-full mb-3">
        <SelectButton
          value={activeContent}
          options={contentOptions}
          optionLabel="name"
          optionValue="value"
          unselectable={false}
          className="mb-3 md:mb-0 w-full md:w-auto inline-flex md:block sm-buttons-w-full"
          onChange={(e) => {
            onUpdateContent(e.value as Contents)
          }}
        />
        <SearchWithStatus
          placeholder="Search orders"
          options={medicationRequestOrdersStatus}
          selectedItems={statusFilter}
          onStatusCheck={updateFilter}
          onSearch={(filter) => {
            startTransition(() => {
              updateSearchFilter(filter ?? "")
            })
          }}
          className="p-fluid w-full md:w-2/3 lg:1/3 xl:max-w-max justify-end"
        />
      </div>
      {isLoading ? (
        loader()
      ) : (
        <>
          {!orders?.length ? (
            <div className="flex flex-col items-center justify-center h-full">
              <FontAwesomeIcon icon={faSearch} size="3x" className="text-slate-400" />
              <p className="text-md text-slate-400 pt-4 pb-2 place-self-center">No orders found</p>
            </div>
          ) : (
            <div className="bg-white flex flex-col h-full overflow-auto">
              <InfiniteScroll
                loadMore={() => {
                  fetchNextPage()
                }}
                hasMore={hasNextPage}
                initialLoad={false}
                useWindow={false}
                loader={loader()}
                className="mr-1"
              >
                {orders.map((order) => (
                  <MedicationOrderListItem
                    key={order.serviceRequest.id}
                    order={order.serviceRequest}
                    isLoading={itemActionClicked === order.serviceRequest.id && isCancelling}
                    onItemClicked={goToOrderDetails}
                    onCancel={() => setOrderToCancel(order)}
                  />
                ))}
              </InfiniteScroll>
            </div>
          )}
        </>
      )}
      <ConfirmDialog />
      <CancelOrderConfirmation
        orderId={orderToCancel?.serviceRequest?.id}
        hasAutoship={orderToCancel?.hasAutoship ?? false}
        invoiceNumber={
          orderToCancel?.invoice?.status === "balanced" ? orderToCancel?.invoice?.identifier?.[0]?.value : undefined
        }
        onHide={() => setOrderToCancel(undefined)}
        onSubmit={({ orderId, reason, stopOrder }) => cancelOrder(orderId, reason, stopOrder)}
      />
    </div>
  )
}

const initialState = {
  statusFilter: ["active"],
  searchFilter: undefined,
  itemActionClicked: "",
} as State

const reducer = (
  state: State,
  {
    type,
    payload,
  }: { type: "action-cliked" | "update-status-filter" | "update-search-filter"; payload: string[] | string },
) => {
  switch (type) {
    case "action-cliked":
      return { ...state, itemActionClicked: payload as string }
    case "update-status-filter":
      return {
        ...state,
        statusFilter: payload as string[],
      }
    case "update-search-filter":
      return {
        ...state,
        searchFilter: payload as string,
      }
    default:
      return state
  }
}

const useReducerState = () => {
  const [{ statusFilter, searchFilter, itemActionClicked }, dispatch] = useReducer(reducer, initialState)

  const onActionCliked = (orderId: string) => {
    dispatch({ type: "action-cliked", payload: orderId })
  }

  const updateFilter = (statusArray: string[]) => {
    dispatch({ type: "update-status-filter", payload: statusArray })
  }

  const updateSearchFilter = (searchText: string) => {
    dispatch({ type: "update-search-filter", payload: searchText })
  }

  return { statusFilter, searchFilter, itemActionClicked, onActionCliked, updateFilter, updateSearchFilter }
}

type State = {
  statusFilter: Array<string>
  searchFilter: string | undefined
  itemActionClicked?: string
}

type Props = {
  activeContent: Contents
  contentOptions: { name: string; value: Contents }[]
  onUpdateContent(activeContent: Contents): void
}

export { MedicationOrdersList }
