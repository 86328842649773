import { faFileInvoiceDollar, faMessages, faPills } from "@fortawesome/pro-regular-svg-icons"

import { useSidebarContext } from "commons"
import { useUnreadedCommunications } from "communication"
import { useCountInvoices } from "invoices"
import { useCountMedicationRequests } from "medication-requests"
import { useLoginContext } from "security"

import { DashboardCard } from "./DashboardCard"

const Dashboard = () => {
  const { loggedInPatientId } = useLoginContext()
  const { isModuleActive } = useSidebarContext()

  // const { scheduledAppointment, appointmentLoading } = useScheduledAppointment(loggedInPatientId)
  const { count: unreadComms } = useUnreadedCommunications(loggedInPatientId)
  const { medicationRequestsCount: activeMRs, isLoading } = useCountMedicationRequests(loggedInPatientId, ["active"])
  const { issuedInvoicesCount } = useCountInvoices(loggedInPatientId, ["issued"])

  return (
    <div className="flex flex-col h-full w-full bg-white relative p-3 pt-3 lg:p-5 lg:pt-5 pb-0">
      <div className="flex flex-col md:grid-cols-2 md:grid lg:grid-cols-4 overflow-auto gap-4 px-2 pb-5">
        {/* {isModuleActive("intake") && <PatientVitalsCard patientId={loggedInPatientId} />} */}
        {/* <ScheduledAppointmentCard scheduledAppointment={scheduledAppointment} isLoading={appointmentLoading} /> */}
        {isModuleActive("communication") && (
          <DashboardCard title="Messages" link="messages" headerIcon={faMessages} isLoading={isLoading}>
            {unreadComms ? (
              <span>
                <b>{unreadComms}</b> unreaded messages
              </span>
            ) : (
              <span>No new messages to read</span>
            )}
            <p className="mt-5">To write new messages or see received ones, click the link below to go chat</p>
          </DashboardCard>
        )}
        <DashboardCard title="Nutraceuticals" link="nutraceuticals" headerIcon={faPills} isLoading={isLoading}>
          {activeMRs ? (
            <span>
              <b>{activeMRs}</b> prescriptions active
            </span>
          ) : (
            <span>No prescriptions active</span>
          )}
        </DashboardCard>
        {/* {isModuleActive("labs") && (
          <DashboardCard title="Lab Orders" link="labs" headerIcon={faVials} isLoading={isLoading}>
            {activeMRs ? (
              <span>
                <b>{activeMRs}</b> lab orders
              </span>
            ) : (
              <span>No lab orders pending</span>
            )}
          </DashboardCard>
        )} */}
        <DashboardCard title="Invoices" link="invoices" headerIcon={faFileInvoiceDollar} isLoading={isLoading}>
          {issuedInvoicesCount ? (
            <span>
              <b>{issuedInvoicesCount}</b> issued invoices
            </span>
          ) : (
            <span>No invoices issued</span>
          )}
        </DashboardCard>
      </div>
    </div>
  )
}

export { Dashboard }
