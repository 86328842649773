import { Form, Formik, FormikErrors, FormikHelpers, FormikProps, FormikValues, useFormikContext } from "formik"
import { ObjectSchema } from "yup"
import { PropsWithChildren, ReactNode, createContext, useEffect, useState } from "react"
import { classNames } from "primereact/utils"
import { useIsMutating } from "@tanstack/react-query"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-solid-svg-icons"

import { Button } from "../components/Buttons"

const FormContainerContext = createContext<FormContainerContextProps<FormikValues> | undefined>(undefined)
FormContainerContext.displayName = "FormContainerContext"

const FormContainer = <T extends FormikValues>({
  children,
  onSubmit,
  mutable,
  cancelButtonLabel,
  closeButton,
  onClose,
  showCloseIcon = true,
  ...props
}: FormContainerProps<T>) => {
  const isMutating = useIsMutating()
  const isSubmitting = isMutating !== 0
  const [submitHandler, setSubmitHandler] = useState<(data: T) => boolean>()
  const [formState, setFormState] = useState<UpdateableFormProps<T>>(props)

  const {
    title,
    subTitle,
    initialValue,
    showCancel = true,
    showSave = !props.customSaveButton,
    saveLabel = "Save",
    disableSave,
    validationSchema,
    onCancel,
    className,
    enableReinitialize,
    footerClassName,
    innerContainerClassName = "px-4 sm:px-6 space-y-6 pb-6",
    hideButtonsDivider,
  } = mutable ? formState : { ...formState, ...props }

  const updateSubmitHandler = (onSubmit: (data: T) => boolean) => setSubmitHandler(() => onSubmit)

  return (
    <FormContainerProvider<T>
      mutable={mutable ?? false}
      handleSubmit={updateSubmitHandler}
      restoreForm={() => {
        setSubmitHandler(undefined)
        setFormState(props)
      }}
      updateFormState={(newState: UpdateableFormProps<T>) => setFormState({ ...formState, ...newState })}
    >
      <Formik
        initialValues={initialValue as T}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize={enableReinitialize}
      >
        {(formikProps: FormikProps<T>) => (
          <Form
            className={classNames("divide-gray-200 flex flex-col h-full grow", className, {
              "divide-y": !hideButtonsDivider,
            })}
            aria-autocomplete="none"
            autoComplete="off"
          >
            <div className="flex flex-1 flex-col overflow-hidden">
              {(title || subTitle) && (
                <div className="px-4 sm:px-6 py-6">
                  {title && (
                    <div className="flex flex-1 justify-between">
                      <h6 className="font-semibold leading-6">{title}</h6>
                      {showCloseIcon && (
                        <FontAwesomeIcon
                          icon={faTimes}
                          size="lg"
                          title="Close"
                          className="hover:bg-primary-hover/10 rounded-full p-1 px-1.5 text-primary focus:outline-primary cursor-pointer"
                          onClick={onClose ?? onCancel}
                        />
                      )}
                    </div>
                  )}
                  {subTitle && <p className="text-gray-300 text-sm mt-1">{subTitle}</p>}
                </div>
              )}
              <div className="flex flex-1 flex-col overflow-y-auto">
                <div className={innerContainerClassName}>
                  {typeof children === "function" ? children(formikProps) : children}
                </div>
              </div>
            </div>
            <div className={classNames("flex flex-shrink-0 justify-end gap-3 px-4 py-4", footerClassName)}>
              {closeButton}
              {showCancel && (
                <Button
                  label={cancelButtonLabel ?? "Close"}
                  buttonStyle="default"
                  size="lg"
                  disabled={isSubmitting}
                  onClick={onCancel}
                />
              )}
              {props.customSaveButton
                ? typeof props.customSaveButton === "function"
                  ? props.customSaveButton({
                      validate: formikProps.validateForm ?? (() => Promise.resolve({})),
                      isSubmitting: formikProps.isSubmitting,
                      values: formikProps.values as T,
                    })
                  : props.customSaveButton
                : showSave && (
                    <Button
                      label={saveLabel}
                      size="lg"
                      loading={isSubmitting}
                      disabled={isSubmitting || disableSave}
                      onClick={() => {
                        const continueSubmitting = submitHandler?.(formikProps.values) ?? true
                        continueSubmitting && formikProps.submitForm()
                      }}
                    />
                  )}
            </div>
            <ScrollToError />
          </Form>
        )}
      </Formik>
    </FormContainerProvider>
  )
}

const ScrollToError = () => {
  const { errors, isSubmitting, isValidating } = useFormikContext()

  useEffect(() => {
    if (isSubmitting && !isValidating && errors) {
      const fields = Object.entries(errors) as FieldErrorType[]

      if (fields.length > 0) {
        const fieldError = getFieldError(fields)

        const selector = `[name^='${fieldError}'], [id^='errorMessage.${fieldError}']`
        const errorElement = document.querySelector(selector) as HTMLElement

        if (errorElement) errorElement.scrollIntoView({ behavior: "smooth", block: "center" })
      }
    }
  }, [errors, isSubmitting, isValidating])
  return null
}

const getFieldError = (fields: FieldErrorType[]): string => {
  const [key, value] = fields[0]
  if (typeof value === "string") return key

  if (Array.isArray(value)) {
    const index = value.findIndex((d) => d)
    return `${key}[${index}].${getFieldError(Object.entries(value[index]))}`
  }

  return `${key}.${getFieldError(Object.entries(value))}`
}

const FormContainerProvider = <T extends FormikValues>({
  children,
  ...rest
}: PropsWithChildren<FormContainerContextProps<T>>) => {
  return <FormContainerContext.Provider value={{ ...rest }}>{children}</FormContainerContext.Provider>
}

type FieldErrorType = [string, string | object | []]

type FormContainerContextProps<T extends FormikValues> = {
  mutable: boolean
  handleSubmit(onSubmit: (data: T) => boolean): void
  restoreForm(): void
  updateFormState(newState: UpdateableFormProps<T>): void
}

type UpdateableFormProps<T extends FormikValues> = Omit<
  FormContainerProps<T>,
  "onSubmit" | "children" | "initialValue"
> & {
  initialValue?: FormikValues
}

export type FormContainerProps<T extends FormikValues> = {
  title?: string
  subTitle?: string
  className?: string
  footerClassName?: string
  innerContainerClassName?: string
  cancelButtonLabel?: string
  showCancel?: boolean
  showSave?: boolean
  disableSave?: boolean
  saveLabel?: string
  customSaveButton?:
    | ReactNode
    | (({
        validate,
        isSubmitting,
        values,
      }: {
        validate: () => Promise<FormikErrors<unknown>>
        isSubmitting?: boolean
        values: T
      }) => ReactNode)
  initialValue: T
  onSubmit(data: T, formikHelpers?: FormikHelpers<T>): void
  onCancel?(): void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validationSchema?: ObjectSchema<any>
  enableReinitialize?: boolean
  hideButtonsDivider?: boolean
  children: ((props: FormikProps<T>) => ReactNode) | ReactNode
  mutable?: boolean
  closeButton?: ReactNode
  showCloseIcon?: boolean
  onClose?(): void
}

export { FormContainer, FormContainerContext }
