import { useSuspenseQuery } from "@tanstack/react-query"
import { Patient, Reference, asReference, getResources, isPatient, isUser } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { CustomError } from "commons"

import { useAuth } from "../hooks/useAuth"

const useLoggedUserPatients = () => {
  const { read, search } = useClient()
  const { user } = useAuth()

  const queryKey = ["logged-user-patients", user.linkedUser.id]

  const { data, isLoading, isPending, error } = useSuspenseQuery({
    queryKey,
    queryFn: async () => {
      let patients: Patient[] = []

      if (isUser(user.linkedUser)) {
        const filters = new URLSearchParams({
          id: user.linkedUser.id ?? "",
          _include: "link:Patient",
        })

        const bundle = await search({ endpoint: "User", filters })
        patients = getResources<Patient>(bundle, "Patient")
      } else if (isPatient(user.linkedUser)) {
        patients[0] = await read<Patient>({ endpoint: "Patient", id: user.linkedUser.id ?? "" })
      }

      if (!patients.length) {
        throw new Error("Not found", {
          cause: {
            name: "404",
            message: `Not found a patient linked to logged in user ${user.name ?? "unspesified name"} with email ${
              user.email ?? "unspecified email"
            }`,
            logoutFlag: true,
          },
        } as CustomError)
      }

      return patients
    },
    meta: { context: { queryKey, user } },
  })

  const { patientRefs, patientsIndexed } = useMemo(() => {
    const { patientRefs, patientsIndexed } = data.reduce<{
      patientRefs: Reference[]
      patientsIndexed: Record<string, Patient>
    }>(
      (patientsData, patient) => {
        if (patient.id) {
          return {
            ...patientsData,
            patientRefs: [
              ...patientsData.patientRefs,
              {
                ...asReference(patient),
                ...(patient.managingOrganization?.display ? { display: patient.managingOrganization.display } : {}),
              },
            ],
            patientsIndexed: { ...patientsData.patientsIndexed, [patient.id]: patient },
          }
        }

        return patientsData
      },
      { patientRefs: [] as Reference[], patientsIndexed: {} },
    )

    return { patientRefs, patientsIndexed }
  }, [data])

  const isLinkedPatient = (id: string) => patientRefs.some((ref) => ref.id === id)

  return {
    patients: data,
    patientsIndexed,
    patientRefs,
    isLinkedPatient,
    linkedPatientsCount: data.length,
    isLoading: isLoading || isPending,
    error,
  }
}

export { useLoggedUserPatients }
